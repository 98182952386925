import { styled } from 'stitches.config';
import IconBaseType from './IconBaseType';

function LinkedInIcon({ css, size = 'l', ...rest }: IconBaseType) {
  return (
    <>
      <CircleBackground>
        <path d="M14.1526 15.9975C15.1596 15.9975 15.9759 15.1812 15.9759 14.1742C15.9759 13.1672 15.1596 12.3508 14.1526 12.3508C13.1456 12.3508 12.3292 13.1672 12.3292 14.1742C12.3292 15.1812 13.1456 15.9975 14.1526 15.9975Z" />
        <path d="M17.6977 17.3791V27.495H20.8385V22.4925C20.8385 21.1725 21.0868 19.8942 22.7235 19.8942C24.3377 19.8942 24.3577 21.4033 24.3577 22.5758V27.4958H27.5002V21.9483C27.5002 19.2233 26.9135 17.1292 23.7285 17.1292C22.1993 17.1292 21.1743 17.9683 20.7552 18.7625H20.7127V17.3791H17.6977ZM12.5793 17.3791H15.7252V27.495H12.5793V17.3791Z" />
      </CircleBackground>
    </>
  );
}

const CircleBackground = styled('svg', {
  position: 'relative',
  display: 'inline-block',
  backgroundColor: '$darkGrey2',
  borderRadius: '50%',
  fill: '$lightGrey2',
  width: '40px',
  height: '40px',

  '@mediaMinMedium': {
    transform: 'scale(1.2)',
  },

  '&:hover': {
    backgroundColor: '$lightGrey4',
    fill: '$darkGrey2',
    cursor: 'pointer',
  },
});

export default LinkedInIcon;
