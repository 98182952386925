import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function CardIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M29 5H3C1.89543 5 1 5.89543 1 7V25C1 26.1046 1.89543 27 3 27H29C30.1046 27 31 26.1046 31 25V7C31 5.89543 30.1046 5 29 5Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M1 11H31"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M7 17H18"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M7 21H14"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default CardIcon;
