// Documentation for stiches --> https://stitches.dev/docs/styling
import { createStitches } from '@stitches/react';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { utils } from 'Theme/Settings/utils';
import sizes from 'Theme/Settings/sizes';

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    sizes,
    wordSpacings: {
      fontPrimary: 'calc(1em * -0.25)',
    },
    fontWeights: {
      fw300: 300,
      fw400: 400,
      fw500: 500,
      fw600: 600,
      fw700: 700,
    },
    fonts: {
      fontSecondary400: 'fontSecondary400, Arial',
      fontSecondary500: 'fontSecondary500, Arial',
      fontSecondary600: 'fontSecondary600, Arial',
      fontPrimary400: 'fontPrimary400, Arial',
      fontPrimary500: 'fontPrimary500, Arial',
      fontPrimary700: 'fontPrimary700, Arial',
    },
    lineHeights: {
      lh1: 1,
      lh1071: 1.071,
      lh1125: 1.125,
      lh116: 1.166,
      lh12: 1.2,
      lh125: 1.25,
      lh133: 1.33,
      lh15: 1.5,
      lh155: 1.55,
      lh171: 1.71,
    },
    letterSpacings: {
      lsn1: '-1px',
      ls0: '0px',
      ls05: '0.5px',
      ls06: '0.6px',
      ls07: '0.7px',
      ls08: '0.8px',
      ls1: '1px',
      ls113: '1.13px',
      ls125: '1.25px',
      ls15: '1.5px',
      ls1625: '1.625px',
      ls163: '1.63px',
      ls175: '1.75px',
      ls2: '2px',
      ls275: '2.75px',
      ls3: '3px',
      ls8: '8px',
    },
    zIndices: {
      Notification: 9,
      Ribbon: 9,
      Header: 10,
      HeaderMobile: 10,
      Flyout: 10,
      DropDown: 9,
      QuantityDropDown: 10,
      FlyoutLower: 5,
      Cart: 10,
      CartMobile: 4,
      ConfiguratorSummary: 4,
      Modal: 20,
      ProductCardSecondary: 6,
      ProductCardMain: 7,
      ProductCardTag: 8,
      ProductCardFavorite: 8,
      Minicart: 9,
      Tooltip: 40,
      ModalOverlay: 19,
      OverlayMegaMenu: 5,
      pageLoader: 990,
      errorModal: 1000,
    },
    transitions: {
      '200': '200ms',
      '300': '300ms',
      '400': '400ms',
      '500': '500ms',
      '600': '600ms',
      '700': '700ms',
      '1000': '1000ms',
      '1500': '1500ms',
      '5000': '5000ms',
    },
  },
  utils,
  media: mediaQueryTypes,
});
