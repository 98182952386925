import { LogoIcon, DIGasellIcon } from 'Atoms/Icons';
import FooterModel from 'Models/Footer/FooterModel.interface';
import CollapsableSection from 'Molecules/CollapsableSection/CollapsableSection';
import LinkList from 'Molecules/LinkList/LinkList';
import XHtmlArea from 'Molecules/XHtmlArea/XHtmlArea';
import { styled } from 'stitches.config';
import AddressInfo from '../AddressInfo/AddressInfo';
import useQueryFooter from '../useQueryFooter';

type PropTypes = {
  initData: FooterModel;
};

function MobileFooterMid({ initData }: PropTypes) {
  const {
    aboutText,
    addressText,
    footerCenterHeader = '',
    footerRightHeader = '',
    links,
  } = useQueryFooter(initData);

  return (
    <>
      <RowOne>
        <LogoIcon size="headerDesktop" fillColor="white" />
        <TextWrapper>
          <XHtmlArea content={aboutText || ''} />
        </TextWrapper>
        <DIGasellIcon />
      </RowOne>
      <Row>
        <CollapsableSection fullWidth={true} title={footerCenterHeader} key={1}>
          <LinkList links={links} />
        </CollapsableSection>
      </Row>
      <Row>
        <CollapsableSection fullWidth={true} title={footerRightHeader} key={2}>
          <AddressInfo text={addressText} />
        </CollapsableSection>
      </Row>
    </>
  );
}
export default MobileFooterMid;

const fontStyleCss = {
  fontFamily: '$fontSecondary400',
  fontWeight: '$fw400',
  letterSpacing: '$ls0',
  lineHeight: '$lh125',
  color: '$secondary2',
  fs: 8,
};

const TextWrapper = styled('div', {
  ...fontStyleCss,
  pt: 4,
  opacity: 0.9,
  pb: 8,
});

const RowOne = styled('div', {
  pt: 16,
  px: 4,
  pb: 8,
});

const Row = styled('div', {});
