import React from 'react';
import { styled } from 'stitches.config';
import { CSS } from '@stitches/react';

type PropType = {
  width?: 'content' | 'max';
  children: React.ReactNode;
  noPadding?: boolean;
  css?: CSS;
};

function ContentContainer({ width, children, noPadding, ...rest }: PropType) {
  return (
    <Container width={width} {...rest} noPadding={noPadding}>
      {children}
    </Container>
  );
}

export default ContentContainer;

const Container = styled('div', {
  w: '100%',
  mx: 'auto',
  py: 0,
  px: 4,
  variants: {
    width: {
      max: {
        maxW: '$screenMaxWidth',
      },
      content: {
        maxW: '$contentMaxWidth',
      },
    },
    noPadding: {
      true: {
        px: 0,
      },
    },
  },
  defaultVariants: {
    width: 'content',
  },
});
