import { styled } from 'stitches.config';
import { timings } from 'Theme/Settings/animation';
import {
  animationLoadingStrokeDash,
  animationRotation,
} from 'Theme/Settings/keyframes';

export const BaseIconStyle = styled('svg', {
  variants: {
    isLoading: {
      true: {
        animation: `${animationRotation}`,
        animationDuration: timings.twoFifths,
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
      },
    },
    size: {
      normal: {
        w: 4,
        h: 4,
      },
      large: {
        w: 12,
        h: 12,
      },
    },
  },
  defaultVariants: {
    size: 'normal',
  },
});

export const BaseIconPathStyle = styled('path', {
  variants: {
    color: {
      primary: {
        stroke: '$JE68GreenPrimary',
      },
      secondary: {
        stroke: '$JE68GreenPrimary',
      },
      accent: {
        color: '$accent',
      },
      error: {
        stroke: '$errorText',
      },
    },
  },
});

export const BaseIconCircleStyle = styled('circle', {
  strokeLinecap: 'round',
  variants: {
    color: {
      primary: {
        stroke: '$JE68GreenPrimary',
      },
      secondary: {
        stroke: '$JE68GreenPrimary',
      },
      accent: {
        stroke: '$accent',
      },
      error: {
        stroke: '$errorText',
      },
    },
    isLoading: {
      true: {
        animation: `${animationLoadingStrokeDash}`,
        animationDuration: timings.oneAndAHalf,
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
      },
    },
  },
  defaultVariants: {
    color: 'primary',
  },
});

export const BaseIconCircleStyleBackground = styled('circle', {
  strokeLinecap: 'round',
  stroke: '$JE68GreenBackground',
});
