import { styled } from 'stitches.config';
import { CSS } from '@stitches/react';

import {
  BaseH1Style,
  BaseH2Style,
  BaseH3Style,
  BaseH4Style,
  BaseH5Style,
  BaseH6Style,
} from './BaseHeadingStyle';
import React from 'react';

export interface HeadingType {
  children: React.ReactNode;
  noMargin?: boolean;
  color?: 'primary' | 'secondary' | 'secondaryDark' | 'tertiary';
  divider?: boolean;
  css?: CSS;
  center?: boolean;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLHeadingElement>;
}

export function H1({
  children,
  noMargin,
  color,
  divider,
  center,
  style,
  css,
}: HeadingType) {
  return (
    <StyledH1
      noMargin={noMargin}
      color={color}
      style={style}
      divider={divider}
      css={css}
      {...(center && { center })}
    >
      {children}
    </StyledH1>
  );
}

export function H2({
  children,
  noMargin,
  color,
  divider,
  center,
  style,
  css,
}: HeadingType) {
  return (
    <StyledH2
      noMargin={noMargin}
      color={color}
      style={style}
      divider={divider}
      css={css}
      {...(center && { center })}
    >
      {children}
    </StyledH2>
  );
}

export function H3({
  children,
  noMargin,
  color,
  divider,
  onClick,
  center,
  style,
  css,
}: HeadingType) {
  return (
    <StyledH3
      noMargin={noMargin}
      color={color}
      style={style}
      divider={divider}
      onClick={onClick}
      css={css}
      {...(center && { center })}
    >
      {children}
    </StyledH3>
  );
}

export function H4({
  children,
  noMargin,
  color,
  divider,
  center,
  style,
  css,
}: HeadingType) {
  return (
    <StyledH4
      noMargin={noMargin}
      color={color}
      style={style}
      divider={divider}
      css={css}
      {...(center && { center })}
    >
      {children}
    </StyledH4>
  );
}

export function H5({
  children,
  noMargin,
  color,
  divider,
  center,
  style,
  css,
}: HeadingType) {
  return (
    <StyledH5
      noMargin={noMargin}
      color={color}
      style={style}
      divider={divider}
      css={css}
      {...(center && { center })}
    >
      {children}
    </StyledH5>
  );
}

export function H6({
  children,
  noMargin,
  color,
  divider,
  center,
  style,
  css,
}: HeadingType) {
  return (
    <StyledH6
      noMargin={noMargin}
      color={color}
      style={style}
      divider={divider}
      css={css}
      {...(center && { center })}
    >
      {children}
    </StyledH6>
  );
}

const StyledH1 = styled('h1', BaseH1Style);
const StyledH2 = styled('h2', BaseH2Style);
const StyledH3 = styled('h3', BaseH3Style);
const StyledH4 = styled('h4', BaseH4Style);
const StyledH5 = styled('h5', BaseH5Style);
const StyledH6 = styled('h6', BaseH6Style);
