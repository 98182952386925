import { BaseH6Style } from 'Atoms/Typography/Headings/BaseHeadingStyle';
import { H3 } from 'Atoms/Typography/Headings/Heading';
import XHtmlArea from 'Molecules/XHtmlArea/XHtmlArea';
import { styled } from 'stitches.config';

type PropTypes = {
  header?: string;
  text?: string;
};

function AddressInfo({ header, text }: PropTypes) {
  return (
    <>
      {header && (
        <H3
          color="secondary"
          noMargin={true}
          css={{
            marginBottom: '16px',
            marginTop: '8px',
            ...BaseH6Style,
          }}
        >
          {header.toUpperCase()}
        </H3>
      )}
      <Wrapper>
        <XHtmlArea content={text || ''} css={style} />
      </Wrapper>
    </>
  );
}

const fontStyleCss = {
  fontFamily: '$fontSecondary400',
  fontWeight: '$fw400',
  letterSpacing: '$ls0',
  lineHeight: '$lh125',
  color: '$secondary2',
  fs: 8,
};

const Wrapper = styled('div', {
  ...fontStyleCss,
});

const style = {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  wordSpacing: 0,
  lineHeight: '$lh15',
};
export default AddressInfo;
