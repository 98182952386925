const variations = {
  variants: {
    noMargin: {
      true: {
        margin: 'unset',
      },
    },
    color: {
      primary: {
        color: '$JE68GreenPrimary',
      },
      secondary: {
        color: '$secondary2',
      },
      secondaryDark: {
        color: '$primary6',
      },
      tertiary: {
        color: '$pureWhite',
      },
    },
    center: {
      true: {
        margin: 'auto',
      },
    },
    divider: {
      true: {
        '&:after': {
          position: 'relative',
          display: 'flex',
          l: 0,
          t: 2,
          h: 0.25,
          w: 16,
          content: '""',
          borderTop: 'solid 1px $textPrimary',
        },
      },
    },
    tag: { h1: {}, h2: {}, h3: {}, h4: {}, h5: {}, h6: {} },
  },
};

export const baseHeadingStyle = {
  color: '$textPrimary',
  letterSpacing: '$ls1',
  wordSpacing: '$wordSpacings$fontPrimary',
  textTransform: 'uppercase',
};

export const BaseH1Style = {
  ...variations,
  ...baseHeadingStyle,
  fs: 16,
  lineHeight: '$lh1125',
  fontFamily: '$fontPrimary700',
  fontWeight: '$fw700',
  '@mediaMinLarge': {
    fs: 28,
    lineHeight: '$lh1071',
  },
};

export const BaseH2Style = {
  ...variations,
  ...baseHeadingStyle,
  fs: 12,
  lineHeight: '$lh12',
  fontFamily: '$fontPrimary500',
  '@mediaMinLarge': {
    fs: 16,
    lineHeight: '$lh1125',
  },
};

export const BaseH3Style = {
  ...variations,
  ...baseHeadingStyle,
  fs: 10,
  lineHeight: '$lh12',
  fontFamily: '$fontPrimary400',
  '@mediaMinLarge': {
    fs: 12,
    lineHeight: '$lh116',
  },
};

export const BaseH4Style = {
  ...variations,
  ...baseHeadingStyle,
  fs: 10,
  fontFamily: 'fontPrimary500',
  lineHeight: '$lh12',
  '@mediaMinLarge': {
    fs: 10,
  },
};

export const BaseH5Style = {
  ...variations,
  ...baseHeadingStyle,
  fontFamily: 'fontPrimary500',
  textTransform: 'unset',
  ls: '$lsn1',
  fs: 8,
  lineHeight: '$lh125',
  '@mediaMinLarge': {
    fs: 10,
    lineHeight: '$lh12',
  },
};

export const BaseH6Style = {
  ...variations,
  ...baseHeadingStyle,
  textTransform: 'uppercase',
  fontFamily: 'fontPrimary500',
  mb: 4,
  fs: 6,
  lineHeight: '$lh133',
  '@mediaMinLarge': {
    fs: 8,
    lineHeight: '$lh125',
  },
};
