import { useKexLoadingCircle } from 'Kex/KexLoadingCircle';
import FooterModel from 'Models/Footer/FooterModel.interface';
import { useEffect } from 'react';
import useSWR from 'swr';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';

import FetchFooter from './FetchFooter';

export default function useQueryFooter(initialData: FooterModel): FooterModel {
  const { languageRoute } = useAppSettingsData();
  const [, kexLoadingCircleDispatch] = useKexLoadingCircle();

  const { data: queryData, isValidating } = useSWR(
    `/App/GetStandardFooter?language=${languageRoute}`,
    FetchFooter,
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(
    () =>
      kexLoadingCircleDispatch({
        type: isValidating && queryData !== initialData ? 'add' : 'remove',
        from: 'queryFooter',
      }),
    [isValidating, initialData, queryData, kexLoadingCircleDispatch]
  );

  return queryData || initialData;
}
