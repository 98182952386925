import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function FrameChassiIcon({
  css,
  color,
  size,
  space,
  onBackground,
}: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M24.08 8L1 19.02H9.3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M31 19.6L24 8L21.38 18.9C21.1897 19.6614 20.7572 20.3403 20.1476 20.8345C19.538 21.3287 18.7843 21.6114 18 21.64H10.32L8 15.72"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M20.2001 20.8L15.1201 12.28"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default FrameChassiIcon;
