import { useContext, createContext, useState, useEffect } from 'react';
import useHideHeader from 'Shared/Hooks/useHideHeader';

type PropType = {
  children: React.ReactNode;
};
type ContextType = {
  isHideHeader: boolean;
  shouldAnimateSticky: boolean;
};

const StickyHeaderContext = createContext({});

export const StickyHeaderProvider = ({ children }: PropType) => {
  const isHideHeader = useHideHeader();

  const [shouldAnimateSticky, setShouldAnimateSticky] =
    useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => setShouldAnimateSticky(isHideHeader), 200);

    return () => {
      clearTimeout(timer);
    };
  }, [isHideHeader]);

  return (
    <StickyHeaderContext.Provider value={{ isHideHeader, shouldAnimateSticky }}>
      {children}
    </StickyHeaderContext.Provider>
  );
};

export const useStickyHeaderData = (): ContextType => {
  return useContext(StickyHeaderContext) as ContextType;
};
