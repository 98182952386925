import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function ShipmentIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M1.02319 17.6293L4.93919 19.612"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M1.14844 12.312L4.89777 14.32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M2.72852 7.89066L4.98052 9.27866"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M14.4805 6.86798L25.4805 12.488"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M18.7512 4.68532L10.5005 8.90132C10.0405 9.13994 9.65522 9.50081 9.38705 9.94423C9.11888 10.3877 8.9782 10.8965 8.9805 11.4147V20.5867C8.97833 21.105 9.11921 21.6139 9.38762 22.0573C9.65603 22.5008 10.0416 22.8616 10.5018 23.1L18.7512 27.3147C19.1318 27.5098 19.5534 27.6116 19.9812 27.6116C20.4089 27.6116 20.8305 27.5098 21.2112 27.3147L29.4605 23.1C29.9206 22.8615 30.3059 22.5006 30.5741 22.0572C30.8423 21.6137 30.9829 21.1049 30.9805 20.5867V11.4147C30.9831 10.8964 30.8425 10.3875 30.5743 9.944C30.3061 9.50052 29.9207 9.13971 29.4605 8.90132L21.2112 4.68532C20.8303 4.4908 20.4088 4.38937 19.9812 4.38937C19.5535 4.38937 19.132 4.4908 18.7512 4.68532V4.68532Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M30.5458 9.89999L19.9805 15.2973L9.4165 9.89999"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M19.9805 15.2973V27.612"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M25.4805 12.488V16.3706"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default ShipmentIcon;
