import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function SupportIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M6 24H5C3.93913 24 2.92172 23.5786 2.17157 22.8284C1.42143 22.0783 1 21.0609 1 20V16C1 14.9391 1.42143 13.9217 2.17157 13.1716C2.92172 12.4214 3.93913 12 5 12H6C6.26522 12 6.51957 12.1054 6.70711 12.2929C6.89464 12.4804 7 12.7348 7 13V23C7 23.2652 6.89464 23.5196 6.70711 23.7071C6.51957 23.8946 6.26522 24 6 24Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M27 24H26C25.7348 24 25.4804 23.8946 25.2929 23.7071C25.1054 23.5196 25 23.2652 25 23V13C25 12.7348 25.1054 12.4804 25.2929 12.2929C25.4804 12.1054 25.7348 12 26 12H27C28.0609 12 29.0783 12.4214 29.8284 13.1716C30.5786 13.9217 31 14.9391 31 16V20C31 21.0609 30.5786 22.0783 29.8284 22.8284C29.0783 23.5786 28.0609 24 27 24Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M5 12C5 9.08262 6.15893 6.28473 8.22183 4.22183C10.2847 2.15893 13.0826 1 16 1V1C18.9174 1 21.7153 2.15893 23.7782 4.22183C25.8411 6.28473 27 9.08262 27 12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M20 29H23C24.0609 29 25.0783 28.5786 25.8284 27.8284C26.5786 27.0783 27 26.0609 27 25V24"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M18 31H16C15.4696 31 14.9609 30.7893 14.5858 30.4142C14.2107 30.0391 14 29.5304 14 29C14 28.4696 14.2107 27.9609 14.5858 27.5858C14.9609 27.2107 15.4696 27 16 27H18C18.5304 27 19.0391 27.2107 19.4142 27.5858C19.7893 27.9609 20 28.4696 20 29C20 29.5304 19.7893 30.0391 19.4142 30.4142C19.0391 30.7893 18.5304 31 18 31Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default SupportIcon;
