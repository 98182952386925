import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

type SearchIconType = IconBaseType & {
  onClick?: () => void;
};

function SearchIcon({
  css,
  color,
  size,
  onClick,
  onBackground,
}: SearchIconType) {
  return (
    <StyledSvg
      size={size}
      css={css}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      onClick={() => onClick && onClick()}
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        d="M1.96473 17.8093C3.21826 20.7589 5.59216 23.0897 8.56419 24.2889C11.5362 25.4882 14.8629 25.4577 17.8125 24.2042C20.7621 22.9506 23.0928 20.5768 24.2921 17.6047C25.4914 14.6327 25.4609 11.306 24.2074 8.35641C22.9538 5.40686 20.5799 3.07607 17.6079 1.8768C14.6359 0.677525 11.3092 0.708008 8.3596 1.96154C5.41004 3.21507 3.07926 5.58897 1.87998 8.561C0.680714 11.533 0.711198 14.8598 1.96473 17.8093V17.8093Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        onBackground={onBackground}
        color={color}
        d="M21.6299 21.6266L31.0019 31"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default SearchIcon;
