import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function RemoveIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M1.33545 6.66669H30.6688"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M19.002 1.33334H13.002C12.4715 1.33334 11.9628 1.54406 11.5877 1.91913C11.2127 2.2942 11.002 2.80291 11.002 3.33334V6.66668H21.002V3.33334C21.002 2.80291 20.7912 2.2942 20.4162 1.91913C20.0411 1.54406 19.5324 1.33334 19.002 1.33334Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M13.002 23.6667V13.6667"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M19.002 23.6667V13.6667"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M25.1486 28.8267C25.1115 29.3278 24.886 29.7962 24.5173 30.1376C24.1487 30.479 23.6644 30.6681 23.162 30.6667H8.84195C8.33951 30.6681 7.85521 30.479 7.48658 30.1376C7.11795 29.7962 6.89238 29.3278 6.85529 28.8267L5.00195 6.66669H27.002L25.1486 28.8267Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default RemoveIcon;
