import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function BreaksIcon({
  css,
  fillColor,
  size,
  space,
  onBackground,
  color,
}: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M15 6C15.5523 6 16 5.55228 16 5C16 4.44772 15.5523 4 15 4C14.4477 4 14 4.44772 14 5C14 5.55228 14.4477 6 15 6Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M9 10C9.55228 10 10 9.55228 10 9C10 8.44772 9.55228 8 9 8C8.44772 8 8 8.44772 8 9C8 9.55228 8.44772 10 9 10Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M4.53809 13.6C5.09037 13.6 5.53809 13.1523 5.53809 12.6C5.53809 12.0477 5.09037 11.6 4.53809 11.6C3.9858 11.6 3.53809 12.0477 3.53809 12.6C3.53809 13.1523 3.9858 13.6 4.53809 13.6Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M5.51416 17.072C6.06644 17.072 6.51416 16.6243 6.51416 16.072C6.51416 15.5197 6.06644 15.072 5.51416 15.072C4.96188 15.072 4.51416 15.5197 4.51416 16.072C4.51416 16.6243 4.96188 17.072 5.51416 17.072Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M6.48804 20.544C7.04032 20.544 7.48803 20.0963 7.48803 19.544C7.48803 18.9917 7.04032 18.544 6.48804 18.544C5.93575 18.544 5.48804 18.9917 5.48804 19.544C5.48804 20.0963 5.93575 20.544 6.48804 20.544Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M8.53418 25.9C9.08646 25.9 9.53418 25.4523 9.53418 24.9C9.53418 24.3477 9.08646 23.9 8.53418 23.9C7.98189 23.9 7.53418 24.3477 7.53418 24.9C7.53418 25.4523 7.98189 25.9 8.53418 25.9Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M12.136 26.044C12.6883 26.044 13.136 25.5963 13.136 25.044C13.136 24.4917 12.6883 24.044 12.136 24.044C11.5837 24.044 11.136 24.4917 11.136 25.044C11.136 25.5963 11.5837 26.044 12.136 26.044Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M15.74 26.19C16.2923 26.19 16.74 25.7423 16.74 25.19C16.74 24.6377 16.2923 24.19 15.74 24.19C15.1877 24.19 14.74 24.6377 14.74 25.19C14.74 25.7423 15.1877 26.19 15.74 26.19Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M21.4661 25.9C22.0183 25.9 22.4661 25.4523 22.4661 24.9C22.4661 24.3477 22.0183 23.9 21.4661 23.9C20.9138 23.9 20.4661 24.3477 20.4661 24.9C20.4661 25.4523 20.9138 25.9 21.4661 25.9Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M22.718 22.518C23.2703 22.518 23.718 22.0703 23.718 21.518C23.718 20.9657 23.2703 20.518 22.718 20.518C22.1657 20.518 21.718 20.9657 21.718 21.518C21.718 22.0703 22.1657 22.518 22.718 22.518Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M23.968 19.136C24.5203 19.136 24.968 18.6883 24.968 18.136C24.968 17.5837 24.5203 17.136 23.968 17.136C23.4157 17.136 22.968 17.5837 22.968 18.136C22.968 18.6883 23.4157 19.136 23.968 19.136Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M22.6321 11.366C23.1844 11.366 23.6321 10.9183 23.6321 10.366C23.6321 9.81371 23.1844 9.366 22.6321 9.366C22.0798 9.366 21.6321 9.81371 21.6321 10.366C21.6321 10.9183 22.0798 11.366 22.6321 11.366Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M19.8042 9.13001C20.3565 9.13001 20.8042 8.68229 20.8042 8.13001C20.8042 7.57772 20.3565 7.13 19.8042 7.13C19.2519 7.13 18.8042 7.57772 18.8042 8.13001C18.8042 8.68229 19.2519 9.13001 19.8042 9.13001Z"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M15 30C22.732 30 29 23.732 29 16C29 8.26801 22.732 2 15 2C7.26801 2 1 8.26801 1 16C1 23.732 7.26801 30 15 30Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M26.314 4.686C27.7998 6.17175 28.9784 7.93562 29.7825 9.87688C30.5867 11.8181 31.0005 13.8988 31.0005 16C31.0005 18.1012 30.5867 20.1819 29.7825 22.1231C28.9784 24.0644 27.7998 25.8283 26.314 27.314"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M24.8999 6.10001C26.2001 7.40004 27.2314 8.94346 27.9351 10.6421C28.6387 12.3408 29.0009 14.1614 29.0009 16C29.0009 17.8386 28.6387 19.6593 27.9351 21.3579C27.2314 23.0566 26.2001 24.6 24.8999 25.9"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M23.4861 7.51401C24.6006 8.62834 25.4847 9.9513 26.0879 11.4073C26.6911 12.8634 27.0015 14.424 27.0015 16C27.0015 17.576 26.6911 19.1366 26.0879 20.5927C25.4847 22.0487 24.6006 23.3717 23.4861 24.486"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M14.9939 21C17.7553 21 19.9939 18.7614 19.9939 16C19.9939 13.2386 17.7553 11 14.9939 11C12.2325 11 9.9939 13.2386 9.9939 16C9.9939 18.7614 12.2325 21 14.9939 21Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default BreaksIcon;
