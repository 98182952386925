import FooterModel from 'Models/Footer/FooterModel.interface';
import { styled } from 'stitches.config';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import useQueryFooter from '../useQueryFooter';

import { KlarnaIcon, VisaCardIcon, MasterCardIcon } from 'Atoms/Icons';

import KexLink from 'Kex/KexLink';
import SocialLinkModel from 'Models/SocialLink/SocialLinkModel.interface';
declare var Cookiebot: any; //Window.Cookiebot

type PropTypes = {
  initData: FooterModel;
};

const today = new Date();
const year = today.getFullYear();

function DesktopFooterBottom({ initData }: PropTypes) {
  const { translations } = useAppSettingsData();
  const { termsAndConditionsLink, privacyPolicyLink, socialLinks } =
    useQueryFooter(initData);

  return (
    <>
      <StyledGrid>
        <ColumnOne>
          <p>
            © {translations.commonLabels.copyrightText} {year}
          </p>
          <StyledKexLink href={privacyPolicyLink.href}>
            {privacyPolicyLink.text}
          </StyledKexLink>
          <StyledKexLink href={termsAndConditionsLink.href}>
            {termsAndConditionsLink.text}
          </StyledKexLink>

          <StyledKexLink
            href="#"
            onClick={() => {
              Cookiebot.renew();
            }}
          >
            {translations.commonLabels.cookiePreferences}
          </StyledKexLink>
        </ColumnOne>

        <ColumnTwo>
          <KlarnaIcon />
          <VisaCardIcon />
          <MasterCardIcon />
        </ColumnTwo>

        <ColumnThree>
          {socialLinks.map((item: SocialLinkModel, index: number) => {
            return (
              <StyledKexLink
                key={item.url + index}
                href={item.url}
                underlined={false}
                aria-label={'social media link'}
              >
                <CircleBackground>
                  <StyledImg src={item.icon} alt={''}></StyledImg>
                </CircleBackground>
              </StyledKexLink>
            );
          })}
        </ColumnThree>
      </StyledGrid>
    </>
  );
}

const StyledGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '3fr 2fr 1fr',
  gap: '60px',
});

const ColumnOne = styled('div', {
  maxWidth: '533px',
  display: 'flex',
  flexBasis: '100%',
  justifyContent: 'start',
  marginLeft: '8px',
  alignItems: 'center',
  fontSize: '12px',
  color: '$secondary2',
  gap: '24px',
});

const ColumnTwo = styled('div', {
  display: 'flex',
  flexBasis: '100%',
  justifyContent: 'start',
  alignItems: 'center',
  gap: '16px',
});

const ColumnThree = styled('div', {
  whiteSpace: 'nowrap',
  marginLeft: 'auto',
  marginRight: '8px',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '16px',
  display: 'flex',
});

const CircleBackground = styled('span', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$primary2',
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  '&:hover': {
    backgroundColor: '$primary1',
    cursor: 'pointer',
  },
  '@mediaMinLarge': { width: '48px', height: '48px' },
});

const StyledImg = styled('img', {
  margin: 'auto',
});

const StyledKexLink = styled(KexLink, {
  fontSize: '12px',
  fontFamily: '$fontSecondary400',
  wordSpacing: 'unset',
  marginBottom: '0px',
});

export default DesktopFooterBottom;
