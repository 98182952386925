import { styled } from 'stitches.config';
import IconBaseType from './IconBaseType';

function FacebookIcon({ css, size = 'l', ...rest }: IconBaseType) {
  return (
    <CircleBackground>
      <StyledPath d="M21.164 27.4974V20.6674H23.4682L23.8107 17.9932H21.164V16.2899C21.164 15.5182 21.379 14.9899 22.4865 14.9899H23.8899V12.6057C23.2071 12.5326 22.5207 12.4972 21.834 12.4999C19.7974 12.4999 18.399 13.7432 18.399 16.0257V17.9882H16.1099V20.6624H18.404V27.4974H21.164Z" />
    </CircleBackground>
  );
}

const CircleBackground = styled('svg', {
  position: 'relative',
  display: 'inline-block',
  backgroundColor: '$primary2',
  borderRadius: '50%',

  width: '40px',
  height: '40px',

  '@mediaMinMedium': {
    transform: 'scale(1.2)',
  },

  '&:hover': {
    backgroundColor: '$primary1',

    cursor: 'pointer',
  },
});

const StyledPath = styled('path', {
  margin: 'auto',
});

export default FacebookIcon;
