import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function PlasticPartsIcon({
  css,
  color,
  size,
  space,
  onBackground,
}: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M16 31C17.7996 31.1455 19.6103 30.9323 21.3269 30.3727C23.0435 29.813 24.6319 28.9182 26 27.74C28.66 25.22 31 18 31 14.64C31 12.22 29.12 11.58 27.56 9.26C26.6 7.86 26.58 6.12 25.22 4.5C23.86 2.88 21.72 1 16 1C10.28 1 8.24 2.76 6.78 4.5C5.32 6.24 5.4 7.86 4.44 9.26C2.88 11.58 1 12.22 1 14.64C1 18 3.34 25.22 6 27.74C7.36812 28.9182 8.95654 29.813 10.6731 30.3727C12.3897 30.9323 14.2004 31.1455 16 31V31Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M16.0001 23.12C12.0001 23.12 7.1001 19 7.1001 15V12C12.9585 10.6665 19.0417 10.6665 24.9001 12V15C24.9001 19 20.0001 23.12 16.0001 23.12Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M18 26.92C19.9318 26.8337 21.7751 26.0851 23.22 24.8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M14 26.92C12.0682 26.8337 10.225 26.0851 8.78003 24.8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M16 31C17.7996 31.1455 19.6103 30.9322 21.3269 30.3726C23.0435 29.813 24.6319 28.9182 26 27.74"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default PlasticPartsIcon;
