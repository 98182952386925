import FooterModel from 'Models/Footer/FooterModel.interface';

import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';

import DesktopFooterMid from './DesktopFooterMid/DesktopFooterMid';
import MobileFooterMid from './MobileFooterMid/MobileFooterMid';
import DesktopFooterBottom from './DesktopFooterBottom/DesktopFooterBottom';
import MobileFooterBottom from './MobileFooterBottom/MobileFooterBottom';

import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';

type PropTypes = {
  initData: FooterModel;
};

function Footer({ initData }: PropTypes) {
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  const { inEditMode } = useCurrentPage<PageModelBase>();

  return (
    <FooterElm>
      {isDesktop ? (
        <ContentContainer width="content">
          <DesktopFooterMid initData={initData} />
        </ContentContainer>
      ) : (
        <ContentContainer width="content" noPadding={true}>
          <MobileFooterMid initData={initData} />
        </ContentContainer>
      )}
      <ContentContainer width="content">
        <LowerFooterContainer>
          {isDesktop || inEditMode ? (
            <DesktopFooterBottom initData={initData} />
          ) : (
            <MobileFooterBottom initData={initData} />
          )}
        </LowerFooterContainer>
      </ContentContainer>
    </FooterElm>
  );
}

const FooterElm = styled('footer', {
  color: '$footerTextPrimary',
  backgroundColor: '$primary4',
});

const LowerFooterContainer = styled('div', {
  pb: 12,
  pt: 12,
  '@mediaMinLarge': {
    pt: 3,
  },
});

export default Footer;
