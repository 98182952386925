import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import { Helmet } from 'react-helmet-async';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';

function PageMetaData() {
  const {
    alternativeLinks,
    canonicalLink,
    disableIndexability,
    metaDescription,
    openGraphDescription,
    openGraphImage,
    openGraphTitle,
    openGraphType,
    pageTitle,
  } = useCurrentPage<PageModelBase>();
  return (
    <Helmet>
      <title>{pageTitle}</title>
      {Object.keys(alternativeLinks).reduce((acc, key: string) => {
        return (
          acc +
          '<link rel="alternate" href="' +
          alternativeLinks[key] +
          '" hreflang="' +
          key +
          '" /> \n'
        );
      }, '')}
      {canonicalLink && <link rel="canonical" href={canonicalLink} />}
      {metaDescription && <meta name="description" content={metaDescription} />}
      {openGraphTitle && <meta property="og:title" content={openGraphTitle} />}
      {openGraphType && <meta property="og:type" content={openGraphType} />}
      {canonicalLink && <meta property="og:url" content={canonicalLink} />}
      {openGraphDescription && (
        <meta property="og:description" content={openGraphDescription} />
      )}
      {openGraphImage && (
        <meta property="og:image" content={openGraphImage?.src} />
      )}
      {disableIndexability && (
        <meta name="robots" content="noindex, nofollow" />
      )}
    </Helmet>
  );
}

export default PageMetaData;
