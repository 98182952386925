import IconBaseType from './IconBaseType';

function VisaCardIcon({ css, size = 'l', ...rest }: IconBaseType) {
  return (
    <>
      <svg
        width="39"
        height="24"
        viewBox="0 0 39 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 2.02872C0 0.90829 0.912833 0 2.03781 0H36.1788C37.3042 0 38.2166 0.908073 38.2166 2.02872V21.8566C38.2166 22.9771 37.3037 23.8853 36.1788 23.8853H2.03781C0.912357 23.8853 0 22.9773 0 21.8566V2.02872ZM26.7268 7.23597C26.1881 7.03484 25.344 6.81901 24.2899 6.81901C21.6033 6.81901 19.7109 8.16547 19.6948 10.0952C19.6796 11.5217 21.0458 12.3175 22.0771 12.7923C23.1354 13.2789 23.4912 13.5893 23.4862 14.0238C23.4795 14.6892 22.641 14.9932 21.8595 14.9932C20.7713 14.9932 20.1931 14.8428 19.3003 14.4721L18.9499 14.3143L18.5683 16.5367C19.2033 16.8138 20.3777 17.0539 21.597 17.0663C24.455 17.0663 26.3104 15.7353 26.3315 13.6746C26.3417 12.5453 25.6174 11.6858 24.0487 10.9773C23.0984 10.5181 22.5164 10.2116 22.5225 9.74662C22.5225 9.33397 23.0152 8.89273 24.0796 8.89273C24.9687 8.87899 25.6127 9.07194 26.1145 9.27307L26.3582 9.38763L26.7268 7.23597ZM33.6988 7.00102H31.5979C30.947 7.00102 30.46 7.1778 30.1741 7.82428L26.1362 16.9222H28.9913C28.9913 16.9222 29.4581 15.6989 29.5637 15.4303C29.8757 15.4303 32.6493 15.4346 33.0458 15.4346C33.1271 15.7821 33.3766 16.9222 33.3766 16.9222H35.8995L33.6988 7.00102ZM30.3461 13.3998C30.571 12.8278 31.4294 10.6246 31.4294 10.6246C31.4133 10.6511 31.6526 10.0499 31.7899 9.67714L31.9737 10.5331C31.9737 10.5331 32.4943 12.9028 32.6031 13.3997H30.3461V13.3998ZM14.1478 16.919H16.8656L18.5667 6.99154H15.8477L14.1478 16.919ZM8.92171 12.3923L7.96848 7.83077C7.8039 7.20214 7.32655 7.01456 6.73603 6.99265H2.35177L2.31707 7.1992C3.38356 7.45614 4.33725 7.82688 5.17303 8.28807L7.59029 16.9104L10.467 16.9072L14.7474 6.99879H11.8674L9.20548 13.7686L8.92186 12.3928C8.92181 12.3927 8.92176 12.3925 8.92171 12.3923Z"
          fill="white"
        />
      </svg>
    </>
  );
}

export default VisaCardIcon;
