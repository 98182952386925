import FooterModel from 'Models/Footer/FooterModel.interface';
import GetStandardFooterModel from 'Models/Footer/GetStandardFooterModel.interface';
import Fetcher from 'Shared/Common/Fetcher';

let abortController: AbortController = new AbortController();

function FetchFooter(url: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;

  return Fetcher<FooterModel, GetStandardFooterModel>(
    url,
    signal,
    (res, resolve, reject) => {
      if (res.success) {
        resolve(res.data);
      }
    }
  );
}

export default FetchFooter;
