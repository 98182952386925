import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import LoadingPageType from 'Pages/LoadingPageType/LoadingPageType';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';

import loadable from '@loadable/component';
import { useEffect } from 'react';
import { canUseDOM } from 'Shared/Common/Helpers';

/* not possible to use full dynamic imports, in server context it wont be able to locate chunks */
const loadablePages = {
  StartPage: loadable(
    () => import(/* webpackPrefetch: true */ 'Pages/StartPage/StartPage')
  ),
  StandardPage: loadable(
    () => import(/* webpackPrefetch: true */ 'Pages/StandardPage/StandardPage')
  ),
  ProductPage: loadable(
    () => import(/* webpackPrefetch: true */ 'Pages/ProductPage/ProductPage')
  ),
  BlockPreviewPage: loadable(() => import('./BlockPreviewPage')),
  CategoryPage: loadable(
    () => import(/* webpackPrefetch: true */ 'Pages/CategoryPage/CategoryPage')
  ),
  CheckoutPage: loadable(
    () => import(/* webpackPrefetch: true */ 'Pages/Checkout/CheckoutPage')
  ),
  OrderConfirmationPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ 'Pages/OrderConfirmation/OrderConfirmationPage'
      )
  ),
  SearchPage: loadable(
    () => import(/* webpackPrefetch: true */ 'Pages/SearchPage/SearchPage')
  ),
  AccountPage: loadable(
    () => import(/* webpackPrefetch: true */ 'Pages/AccountPage/AccountPage')
  ),
  NotFoundPage: loadable(() => import('Pages/NotFoundPage/NotFoundPage')),
  ErrorPage: loadable(() => import('Pages/ErrorPage/ErrorPage')),
  NewsArchivePage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ 'Pages/NewsArchivePage/NewsArchivePage'
      )
  ),
  NewsPage: loadable(
    () => import(/* webpackPrefetch: true */ 'Pages/NewsPage/NewsPage')
  ),
  EventOverviewPage: loadable(
    () =>
      import(/* webpackPrefetch: true */ 'Pages/EventPage/EventOverviewPage')
  ),
  EventPage: loadable(
    () => import(/* webpackPrefetch: true */ 'Pages/EventPage/EventPage')
  ),
  MotorcycleConfiguratorPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ 'Pages/MotorcycleConfiguratorPage/MotorcycleConfiguratorPage'
      )
  ),
  ExplodedViewsPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ 'Pages/ExplodedViewsPage/ExplodedViewsPage'
      )
  ),
  CampaignPage: loadable(
    () => import(/* webpackPrefetch: true */ 'Pages/CampaignPage/CampaignPage')
  ),
};

type KexPageType = keyof typeof loadablePages;

const IsPageTypeKey = (x: string): x is KexPageType =>
  Object.keys(loadablePages).includes(x);

const excludePage = ['CheckoutPage', 'NotFoundPage'];

const KexPage = () => {
  const { pageType } = useCurrentPage<PageModelBase>();

  const currentUrl = canUseDOM() && window.location.href;

  useEffect(() => {
    // store window history so we can redirect back from CheckoutPage
    // user first visit from e.g bookmark we don't have access to prev url in javascript api which is why we need sessionStorage
    if (!excludePage.includes(pageType)) {
      sessionStorage.setItem('url', window.location.href);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUrl]);

  if (!IsPageTypeKey(pageType)) {
    return <></>;
  }

  const LoadablePage = loadablePages[pageType];
  return <LoadablePage fallback={<LoadingPageType />} />;
};

export default KexPage;
