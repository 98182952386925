import { BaseH6Style } from 'Atoms/Typography/Headings/BaseHeadingStyle';
import { H3 } from 'Atoms/Typography/Headings/Heading';
import KexLink from 'Kex/KexLink';
import LinkModel from 'Models/Link/LinkModel.interface';
import { styled } from 'stitches.config';

type PropTypes = {
  header?: string;
  links?: LinkModel[];
};

function LinkList({ header, links }: PropTypes) {
  return (
    <>
      {header && (
        <H3
          color="secondary"
          noMargin={true}
          css={{ marginBottom: '16px', marginTop: '8px', ...BaseH6Style }}
        >
          {header.toUpperCase()}
        </H3>
      )}
      <List>
        {links &&
          links.map((item: LinkModel, index: number) => (
            <ListItem key={item.href && item.href + index}>
              <StyledKexLink href={item.href} fontFamily="secondary400">
                {item.text}
              </StyledKexLink>
            </ListItem>
          ))}
      </List>
    </>
  );
}

const List = styled('ul', {
  listStyle: 'none',
  px: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const ListItem = styled('li', {
  ls: '$ls0',
});

const StyledKexLink = styled(KexLink, {
  mb: 0,
});

export default LinkList;
