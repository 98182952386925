import { BaseListItemStyle } from 'Atoms/List/BaseListStyle';
import {
  BaseH1Style,
  BaseH2Style,
  BaseH3Style,
} from 'Atoms/Typography/Headings/BaseHeadingStyle';
import { BaseParagraphStyle } from 'Atoms/Typography/Paragraph/BaseParagraphStyle';
import React, { useEffect, useRef } from 'react';
import { isInternalLink } from 'Shared/Common/Helpers';
import { styled } from 'stitches.config';
import { CSS } from '@stitches/react';

import { BaseKexLinkStyle } from '../../Kex/BaseKexLinkStyle';
import { useKexNavigate } from '../../Kex/KexRouter/KexRouter';

type PropType = {
  content: string;
  smallText?: true;
  css?: CSS;
};

const HtmlArea = ({ content, smallText, ...rest }: PropType) => {
  const elmRef = useRef<HTMLDivElement>(null);
  const kexNavigate = useKexNavigate();
  useEffect(() => {
    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      const url = e.currentTarget.href;
      if (e.currentTarget.target !== '_blank' && isInternalLink({ url })) {
        e.preventDefault();
        kexNavigate(url);
      }
    };

    const links: HTMLAnchorElement[] = elmRef.current
      ? [].slice.call(elmRef.current.querySelectorAll('a'))
      : [];

    links.forEach((link) => link.addEventListener('click', () => onClick));
    return () =>
      links.forEach((link) => link.removeEventListener('click', () => onClick));
  }, [kexNavigate]);

  const StyledHtmlArea = getStyledHtmlArea(smallText);

  return (
    <StyledHtmlArea
      {...rest}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
};

const SmallText = {
  ...BaseKexLinkStyle,
  fs: 6,
  textDecoration: 'underline solid',
  mb: 0,
};

const BigText = {
  ...BaseKexLinkStyle,
  fs: 8,
  textDecoration: 'underline solid',
  mb: 0,
};

const getStyledHtmlArea = (smallText?: true) =>
  styled('div', {
    '& h1': BaseH1Style,
    '& h2': BaseH2Style,
    '& h3': BaseH3Style,
    '& p': smallText ? { ...BaseParagraphStyle, fs: 6 } : BaseParagraphStyle,
    '& a': smallText ? SmallText : BigText,
    '& li': BaseListItemStyle,
    'ol, ul': {
      listStyle: 'initial',
      my: 4,
      pl: 10,
    },
    '> *': {
      display: 'block',
    },
    lineHeight: '$lh155',
  });

export default HtmlArea;
