import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { EventDispatcher, SET_THEME } from '../Shared/Common/EventDispatcher';
import { useAppSettingsData } from '../Shared/Providers/AppSettingsProvider';

import JE68Dark from './dark.theme';

type PropType = {
  theme?: ThemeKey;
  children: React.ReactNode;
};

const loadableThemes = {
  JE68: {
    JE68Dark: JE68Dark,
  },
};

type ThemeKey = keyof typeof loadableThemes.JE68 | '';

const isThemeKey = (key: string | null): key is ThemeKey =>
  key === '' || (!!key && Object.keys(loadableThemes.JE68).includes(key));

const asThemeKey = (key: string | null): ThemeKey =>
  isThemeKey(key) ? key : '';

export const ThemeProvider = ({ theme, children }: PropType) => {
  const baseTheme: ThemeKey = asThemeKey(useAppSettingsData().siteTheme);
  const [siteTheme, setTheme] = useState<ThemeKey>(
    typeof window !== 'undefined' && window.localStorage
      ? getTheme(theme ? theme : baseTheme)
      : baseTheme
  );

  const onThemeSwitch = (theme: ThemeKey, toggle?: boolean) => {
    //Remove toggle when supporting mulitple themes
    if (toggle) {
      let currentTheme = getTheme('');
      theme = currentTheme === theme && theme === 'JE68Dark' ? '' : theme;
    }
    storeTheme(theme, setTheme);
  };

  useEffect(() => {
    EventDispatcher.subscribe(SET_THEME, onThemeSwitch);

    return () => {
      EventDispatcher.unsubscribe(SET_THEME, onThemeSwitch);
    };
  });

  return (
    <div className={siteTheme === '' ? '' : loadableThemes.JE68[siteTheme]}>
      {children}
    </div>
  );
};

const storeTheme = (
  theme: ThemeKey,
  callback: Dispatch<SetStateAction<ThemeKey>>,
  baseTheme?: ThemeKey
) => {
  if (baseTheme) {
    const storedTheme = localStorage.getItem('theme');

    if (isThemeKey(storedTheme)) {
      callback(
        baseTheme !== storedTheme && storedTheme ? storedTheme : baseTheme
      );
    }

    return;
  }
  localStorage.setItem('theme', theme);
  callback(theme);
};

const getTheme = (baseTheme: ThemeKey): ThemeKey => {
  let theme = localStorage.getItem('theme');

  if (isThemeKey(theme)) {
    return theme;
  }
  return baseTheme;
};
