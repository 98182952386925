/** 
  Spacings helper to create spacings with base as 4. Kex-default = 4px. example: 40px padding = theme.spacing(10),
**/

const BASE_SPACING = 4;
export const spacing = (multiplier: number) => BASE_SPACING * multiplier;
//Used for calculations
export const SizeConstants = {
  heroMaxHeight: 420,
  videoMaxHeight: 574,
  modalHeight: 736,
  standardBlockLargerImageHeight: 490,
  standardBlockLargeImageHeight: 480,
  standardBlockMediumImageHeight: 345,
  thirdWidthBlockImageHeight: 312,
  standardBlockSmallImageHeight: 250,
  verySmallBlockImageHeight: 180,
  mobileHeaderHeight: 64,
  desktopHeaderHeight: 128,
  desktopConfiguratorBarHeight: 197,
  desktopHeaderUpperHeight: 72,
  mobileConfiguratorBarHeight: 80,
  configuratorImageHeightSmall: 280,
  configuratorImageHeightMedium: 480,
  quantityDropdownHeight: 200,

  //widths
  contentMaxWidth: 1312,
  maxWidthTablet: 696,
  maxWidthMobile: 343,

  screenMaxWidth: 1440,
  videoMaxWidth: 1024,
  contentResultWidth: 864,
  blockMaxWidthMobile: 600,
  mobileMenuMaxWidth: 310,

  productImageTinyWidth: 80,

  //imageHeight
  thumbnailImageHeight: 48,
  productImageTinyHeight: 64,
  productImageMaxHeight: 220,
  productImageMaxHeightMedium: 720,
  productImageMaxHeightLarge: 736,
  heroImageMobileHeight: 460,
  heroImageDesktopHeight: '70vh',
  heroImageWideScreenHeight: 900,
  heroBlockWideScreenHeight: 600,
  carouselImageHeight: 220,
  carouselImageHeightMedium: 370,
};

//Used in stitches
const widths = {
  screenMaxWidth: `${SizeConstants.screenMaxWidth}px`,
  contentMaxWidth: `${SizeConstants.contentMaxWidth}px`,
  videoMaxWidth: `${SizeConstants.videoMaxWidth}px`,
  maxWidthTablet: `${SizeConstants.maxWidthTablet}px`,
  maxWidthMobile: `${SizeConstants.maxWidthMobile}px`,
  contentResultWidth: `${SizeConstants.contentResultWidth}px`,
  blockMaxWidthMobile: `${SizeConstants.blockMaxWidthMobile}px`,
  mobileMenuMaxWidth: `${SizeConstants.mobileMenuMaxWidth}px`,
};

const imageWidths = {
  productImageTinyWidth: `${SizeConstants.productImageTinyWidth}px`,
};

const imageHeights = {
  thumbnailImageHeight: `${SizeConstants.thumbnailImageHeight}px`,
  productImageTinyHeight: `${SizeConstants.productImageTinyHeight}px`,
  productImageMaxHeight: `${SizeConstants.productImageMaxHeight}px`,
  productImageMaxHeightMedium: `${SizeConstants.productImageMaxHeightMedium}px`,
  productImageMaxHeightLarge: `${SizeConstants.productImageMaxHeightLarge}px`,
  carouselImageHeight: `${SizeConstants.carouselImageHeight}px`,
  carouselImageHeightMedium: `${SizeConstants.carouselImageHeightMedium}px`,
};

const heights = {
  heroMaxHeight: `${SizeConstants.heroMaxHeight}px`,
  videoMaxHeight: `${SizeConstants.videoMaxHeight}px`,
  modalHeight: `${SizeConstants.modalHeight}px`,
  standardBlockLargerImageHeight: `${SizeConstants.standardBlockLargerImageHeight}px`,
  standardBlockLargeImageHeight: `${SizeConstants.standardBlockLargeImageHeight}px`,
  standardBlockMediumImageHeight: `${SizeConstants.standardBlockMediumImageHeight}px`,
  thirdWidthBlockImageHeight: `${SizeConstants.thirdWidthBlockImageHeight}px`,
  standardBlockSmallImageHeight: `${SizeConstants.standardBlockLargeImageHeight}px`,
  verySmallBlockImageHeight: `${SizeConstants.verySmallBlockImageHeight}px`,
  mobileHeaderHeight: `${SizeConstants.mobileHeaderHeight}px`,
  desktopHeaderHeight: `${SizeConstants.desktopHeaderHeight}px`,
  desktopHeaderUpperHeight: `${SizeConstants.desktopHeaderUpperHeight}px`,
  quantityDropdownHeight: `${SizeConstants.quantityDropdownHeight}px`,
  heroImageMobileHeight: `${SizeConstants.heroImageMobileHeight}px`,
  heroImageDesktopHeight: `${SizeConstants.heroImageDesktopHeight}`,
  heroImageWideScreenHeight: `${SizeConstants.heroImageWideScreenHeight}px`,
  heroBlockWideScreenHeight: `${SizeConstants.heroBlockWideScreenHeight}px`,
};

const sizes = { ...widths, ...heights, ...imageHeights, ...imageWidths };
export default sizes;
