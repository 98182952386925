import { EventDispatcher, ON_KEX_ERROR } from './EventDispatcher';

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
    credentials: 'same-origin',
  },
};

export default function fetcher<P, T>(
  url: string,
  signal: AbortSignal,
  onComplete: (
    data: T,
    resolve: (value: P | PromiseLike<P>) => void,
    reject: (reason?: any) => void,
    response: Response
  ) => void,
  dispatchError = true
) {
  return new Promise<P | undefined>((resolve, reject) => {
    let response: Response;
    fetch(url, {
      ...defaultOptions,
      signal,
    })
      .then((res) => {
        response = res;
        if (res.ok || res.status === 404) {
          return res.json();
        } else {
          throw new Error(`API: ${res.statusText}`);
        }
      })
      .then((data) => onComplete(data, resolve, reject, response))
      .catch((err) => {
        if (signal.aborted) {
          resolve(undefined);
        } else {
          if (dispatchError) {
            EventDispatcher.dispatch(ON_KEX_ERROR, err);
          } else {
            reject();
          }
        }
      });
  });
}
