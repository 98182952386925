import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function FiltersIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M4.57335 13.7143C6.46689 13.7143 8.00192 12.1793 8.00192 10.2857C8.00192 8.39217 6.46689 6.85715 4.57335 6.85715C2.6798 6.85715 1.14478 8.39217 1.14478 10.2857C1.14478 12.1793 2.6798 13.7143 4.57335 13.7143Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M4.57324 13.7143V30.8572"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M4.57324 1.14285V6.85714"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M27.4305 13.7143C29.3241 13.7143 30.8591 12.1793 30.8591 10.2857C30.8591 8.39217 29.3241 6.85715 27.4305 6.85715C25.537 6.85715 24.002 8.39217 24.002 10.2857C24.002 12.1793 25.537 13.7143 27.4305 13.7143Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M27.4304 6.85714V1.14285"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M27.4304 30.8572V13.7143"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M16.0018 19.4286C17.8954 19.4286 19.4304 17.8935 19.4304 16C19.4304 14.1064 17.8954 12.5714 16.0018 12.5714C14.1083 12.5714 12.5732 14.1064 12.5732 16C12.5732 17.8935 14.1083 19.4286 16.0018 19.4286Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M16.002 1.14285V12.5714"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M16.002 19.4286V30.8571"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default FiltersIcon;
