import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function EyeClosedIcon({
  css,
  color,
  size,
  space,
  onBackground,
}: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M3.70654 28L28.7065 4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M12 24.4017C13.2946 24.8058 14.6438 25.0082 16 25.0017C21.4667 25.095 27.0133 21.255 30.4267 17.4817C30.7949 17.0767 30.9989 16.549 30.9989 16.0017C30.9989 15.4544 30.7949 14.9267 30.4267 14.5217C29.1924 13.1575 27.8207 11.9243 26.3333 10.8417"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M22.7866 8.69504C20.6883 7.60648 18.3636 7.02644 15.9999 7.0017C10.6666 6.90837 5.06655 10.6684 1.57322 14.5217C1.20501 14.9267 1.00098 15.4544 1.00098 16.0017C1.00098 16.549 1.20501 17.0767 1.57322 17.4817C3.46639 19.5981 5.68546 21.3986 8.14655 22.815"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M11.9998 19.095C11.2765 18.0916 10.9399 16.8607 11.0518 15.6288C11.1638 14.3969 11.7168 13.2469 12.6091 12.3902C13.5014 11.5336 14.6731 11.028 15.9085 10.9664C17.1439 10.9048 18.3601 11.2914 19.3332 12.055"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M21 16.0017C21 16.6583 20.8707 17.3085 20.6194 17.9151C20.3681 18.5218 19.9998 19.073 19.5355 19.5372C19.0712 20.0015 18.52 20.3698 17.9134 20.6211C17.3068 20.8724 16.6566 21.0017 16 21.0017"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default EyeClosedIcon;
