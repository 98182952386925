import UserStateModel from 'Models/App/UserStateModel.interface';
import miniCartReducer, {
  MiniCartActionType,
} from 'Organisms/Header/Reducers/miniCartReducer';
import userMenuReducer, {
  UserMenuActionType,
} from 'Organisms/Header/Reducers/userMenuReducer';
import React, { useReducer } from 'react';
import {
  canUseDOM,
  FILTER_URL_CONSTANTS,
  getUrlParameter,
} from '../Common/Helpers';

import useQueryUserState from '../Hooks/useQueryUserState';

type PropType = {
  children: React.ReactNode;
  data: UserStateModel;
};

type AccountType = {
  user: UserStateModel;
  accountDispatch: React.Dispatch<UserMenuActionType>;
  account: ReturnType<typeof userMenuReducer>;
  reload: () => void;
  miniCartDispatch: React.Dispatch<MiniCartActionType>;
  miniCartIsOpen: boolean;
  mountMiniCart: boolean;
};

const UserContext = React.createContext<AccountType>({} as AccountType);

export const UserContextProvider = ({ children, data }: PropType) => {
  const [user, reload] = useQueryUserState(data);

  const [{ miniCartIsOpen, mountMiniCart }, miniCartDispatch] = useReducer(
    miniCartReducer,
    {
      miniCartIsOpen: false,
      mountMiniCart: true,
    }
  );

  const [account, accountDispatch] = useReducer(userMenuReducer, {
    userMenuIsOpen: false,
    query: canUseDOM()
      ? getUrlParameter(FILTER_URL_CONSTANTS.SEARCH_QUERY)
      : '',
  });

  return (
    <UserContext.Provider
      value={{
        user,
        accountDispatch,
        account,
        reload,
        miniCartDispatch,
        miniCartIsOpen,
        mountMiniCart,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserStateData = () => {
  return React.useContext(UserContext);
};
