import { styled } from 'stitches.config';
import IconBaseType from './IconBaseType';

function InstagramIcon({ css, size = 'l', ...rest }: IconBaseType) {
  return (
    <>
      <CircleBackground>
        <path d="M20.0001 17.3966C18.5665 17.3966 17.3966 18.5665 17.3966 20.0001C17.3966 21.4337 18.5665 22.6036 20.0001 22.6036C21.4337 22.6036 22.6036 21.4337 22.6036 20.0001C22.6036 18.5665 21.4337 17.3966 20.0001 17.3966ZM27.8087 20.0001C27.8087 18.922 27.8185 17.8536 27.7579 16.7774C27.6974 15.5274 27.4122 14.4181 26.4981 13.504C25.5821 12.588 24.4747 12.3048 23.2247 12.2442C22.1466 12.1837 21.0782 12.1935 20.002 12.1935C18.9239 12.1935 17.8556 12.1837 16.7794 12.2442C15.5294 12.3048 14.42 12.5899 13.506 13.504C12.5899 14.42 12.3067 15.5274 12.2462 16.7774C12.1856 17.8556 12.1954 18.9239 12.1954 20.0001C12.1954 21.0763 12.1856 22.1466 12.2462 23.2227C12.3067 24.4727 12.5919 25.5821 13.506 26.4962C14.422 27.4122 15.5294 27.6954 16.7794 27.756C17.8575 27.8165 18.9259 27.8067 20.002 27.8067C21.0802 27.8067 22.1485 27.8165 23.2247 27.756C24.4747 27.6954 25.5841 27.4102 26.4981 26.4962C27.4142 25.5802 27.6974 24.4727 27.7579 23.2227C27.8204 22.1466 27.8087 21.0782 27.8087 20.0001V20.0001ZM20.0001 24.006C17.7833 24.006 15.9942 22.2169 15.9942 20.0001C15.9942 17.7833 17.7833 15.9942 20.0001 15.9942C22.2169 15.9942 24.006 17.7833 24.006 20.0001C24.006 22.2169 22.2169 24.006 20.0001 24.006ZM24.17 16.7657C23.6524 16.7657 23.2345 16.3477 23.2345 15.8302C23.2345 15.3126 23.6524 14.8946 24.17 14.8946C24.6876 14.8946 25.1056 15.3126 25.1056 15.8302C25.1057 15.9531 25.0816 16.0748 25.0347 16.1884C24.9877 16.3019 24.9188 16.4051 24.8319 16.492C24.745 16.579 24.6418 16.6479 24.5282 16.6948C24.4146 16.7418 24.2929 16.7659 24.17 16.7657V16.7657Z" />
      </CircleBackground>
    </>
  );
}

const CircleBackground = styled('svg', {
  position: 'relative',
  display: 'inline-block',
  backgroundColor: '$darkGrey2',
  borderRadius: '50%',
  fill: '$lightGrey2',
  width: '40px',
  height: '40px',

  '@mediaMinMedium': {
    transform: 'scale(1.2)',
  },

  '&:hover': {
    backgroundColor: '$lightGrey4',
    fill: '$darkGrey2',
    cursor: 'pointer',
  },
});

export default InstagramIcon;
