import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function CartIcon({ css, color, size, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} viewBox="0 0 32 32">
      <StyledPath
        d="M1.14487 1.14285H6.85915L8.84772 20.9143C8.93038 21.4593 9.20723 21.9561 9.62726 22.3131C10.0473 22.6702 10.5822 22.8634 11.1334 22.8571H25.5334C26.0319 22.8832 26.5251 22.7453 26.9379 22.4646C27.3506 22.184 27.6601 21.776 27.8192 21.3029L30.8592 12.16C30.9725 11.8163 31.0026 11.4505 30.947 11.0929C30.8913 10.7352 30.7516 10.3959 30.5392 10.1029C30.3179 9.79126 30.022 9.54013 29.6786 9.37242C29.3352 9.20471 28.9552 9.12578 28.5734 9.14285H7.65915"
        onBackground={onBackground}
        color={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        d="M25.145 30.8571C24.5138 30.8571 24.0021 30.3455 24.0021 29.7143C24.0021 29.0831 24.5138 28.5714 25.145 28.5714C25.7762 28.5714 26.2878 29.0831 26.2878 29.7143C26.2878 30.3455 25.7762 30.8571 25.145 30.8571Z"
        onBackground={onBackground}
        color={color}
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        d="M10.2878 30.8571C9.65662 30.8571 9.14495 30.3455 9.14495 29.7143C9.14495 29.0831 9.65662 28.5714 10.2878 28.5714C10.919 28.5714 11.4307 29.0831 11.4307 29.7143C11.4307 30.3455 10.919 30.8571 10.2878 30.8571Z"
        onBackground={onBackground}
        color={color}
        strokeWidth="2.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default CartIcon;
