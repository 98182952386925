import { CSS } from '@stitches/react';
import { CloseIcon } from 'Atoms/Icons';
import { styled } from 'stitches.config';

type CloseButtonBaseType = {
  onClick?: () => void;
  css?: CSS;
  size: 'xs' | 's' | 'm' | 'l';
  bgColor: 'primary' | 'secondary' | 'primaryLight' | 'transparent';
};

function CloseButton({
  onClick = () => {},
  css,
  size,
  bgColor = 'primary',
}: CloseButtonBaseType) {
  const iconColor =
    bgColor === 'primary' || bgColor === 'primaryLight'
      ? 'secondary'
      : 'secondaryDark';
  return (
    <CloseBtn
      css={css}
      onClick={onClick}
      size={size}
      aria-label={'close button'}
    >
      {bgColor !== 'transparent' ? (
        <CircleBackground bgColor={bgColor}>
          <CloseIcon fillColor={iconColor} />
        </CircleBackground>
      ) : (
        <CloseIcon fillColor={iconColor} />
      )}
    </CloseBtn>
  );
}
export default CloseButton;

const CloseBtn = styled('button', {
  variants: {
    size: {
      xs: {
        wh: 2,
      },
      s: {
        wh: 4,
      },
      m: {
        wh: 6,
      },
      l: {
        wh: 8,
      },
    },
  },
});

const CircleBackground = styled('span', {
  wh: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  borderRadius: '50%',
  variants: {
    bgColor: {
      primary: {
        backgroundColor: '$primary4',
        '&:hover': {
          backgroundColor: '$primary3',
        },
      },
      primaryLight: {
        backgroundColor: '$primary3',
        '&:hover': {
          backgroundColor: '$primary2',
        },
      },
      secondary: {
        backgroundColor: '$pureWhite',
        '&:hover': {
          backgroundColor: '$secondary1',
        },
      },
    },
  },
});
