import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function AddIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M1.00195 16H31.002"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M16.002 1V31"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default AddIcon;
