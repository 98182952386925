import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function CheckmarkIcon({
  css,
  color,
  size,
  isChecked,
  onBackground,
}: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      size={size}
      isChecked={isChecked}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 10"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        d="M15.8493 0.149667C16.0385 0.328212 16.0513 0.630851 15.8778 0.825628L6.45191 9.7219C6.05893 10.0927 5.45577 10.0927 5.06279 9.7219L0.150725 5.08712C-0.0385027 4.90858 -0.051286 4.60594 0.122173 4.41116L0.150725 4.38177C0.362202 4.18223 0.686791 4.18224 0.898259 4.38179L5.75645 8.96617L15.1017 0.149641C15.3132 -0.0498902 15.6378 -0.0498789 15.8493 0.149667Z"
        fillRule="nonzero"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {
  variants: {
    isChecked: {
      true: {
        visibility: 'visible',
      },
      false: {
        visibility: 'hidden',
      },
    },
  },
});
const StyledPath = styled(BaseIconPathStyle, {});

export default CheckmarkIcon;
