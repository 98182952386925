// Documentation for stiches --> https://stitches.dev/docs/styling
import { createStitches } from '@stitches/react';

const { createTheme } = createStitches({});

export type ColorType = keyof typeof dark.colors;

const dark = createTheme({
  colors: {
    pureWhite: '#ffffff',

    secondary1: '#F9FAFA',
    secondary2: '#F0F0F1',
    secondary3: '#EAEAEB',
    secondary4: '#E1E1E3',
    primaryLight1: '#D2D2D6',
    primaryLight2: '#B4B4BB',
    primaryLight3: '#9696A0',
    primaryLight4: '#787885',
    primaryLight5: '#5A5B67',
    primaryLight6: '#4A4B57',
    primary1: '#3A3A44',
    primary2: '#292A31',
    primary3: '#1E1F24',
    primary4: '#19191D',
    primary5: '#141417',
    primary6: '#0E0E10',

    JE68GreenPrimary: '#00C087',
    JE68GreenHover: '#019F70',
    JE68GreenBackground: '#10362D',

    errorColor: '#FF3A31',

    //Colors with the same purpose need to have the same name on all themes to be overidable.
    //These should be used to support theming and variations, button texts need to be seperated
    //from a specific color so allow themes to controll button, link etc sepretly from the main body

    //Root
    rootBackgroundColor: '$primary6',

    //Backgrounds
    backgroundPrimary: '$primary6',
    backgroundSecondary: '$secondary2',

    //Text
    textPrimary: '$secondary2',
    textSecondary: '$primary6',

    //Borders
    borderPrimary: '$pureWhite',
    // borderBlack: '$black',

    //Errors
    // errorBackground: '$alert200',
    errorText: '$errorColor',
    // errorTextLighter: '$alert200',

    //BoxShadow
    // shadow015: 'rgba(72, 72, 72, 0.15)',
    // shadow005: 'rgba(59, 72, 80, 0.05)',
    // shadowGrey: '$grey',

    ///// ATOMS /////

    //Buttons
    buttonPrimary: '$JE68GreenPrimary',
    buttonPrimary2: '$primary2',
    buttonSecondary: '$textPrimary',
    buttonSecondaryDark: '$primary3',
    buttonHoverPrimary: '$JE68GreenHover',
    buttonHoverSecondary: '$primaryLight2',
    buttonHoverSecondaryDark: '$primary4',

    //Links
    linkText: '$textPrimary',

    // Since we have images with white bg, we need to add contrast. On used motorcycles we get good images.
    imageFilter: 'contrast(85%)',

    //Icon Fills
    // fillPrimary: '$primary',
    // fillSecondary: '$secondary',
    fillError: '$errorColor',
    // fillWhite: '$white',
    // fillAccent: '$accent',

    //Icon Colors
    // iconBlue: '#253B80',
    // iconBlueLighter: '#179BD7',

    //Inputs
    inputBackground: '$primary6',
    inputText: '$textPrimary',
    inputPlaceholderText: '$primaryLight3',
    inputBorderColor: '$primaryLight2',

    inputDisabledBorderColor: '$primary1',
    inputDisabledbackground: '$primary5',
    inputDisabledText: '$primaryLight4',

    //Tooltip
    tooltipBorder: '$primary3',

    //Toggle
    // toggleBackground: '$grey700',
    // toggleActiveColor: '$white',
    // toggleCircleBackground: '$grey1100',

    // Quantity Selector
    quantityBackground: '$primary3',
    // quantityBorder: '$primary6',
    // quantityHover: '$primary2',

    // Radio button
    radioIconColor: '$textPrimary',
    radioTextColor: '$textPrimary',
    radioBackground: '$backgroundPrimary',

    // Filter chips
    filterChipsColor: '$textPrimary',
    filterChipsBorder: '$primaryLight4',

    // Tab Group Button
    tabButtonText: '$textPrimary',
    tabButtonBackground: '$primary3',
    tabButtonHoverBackground: '$primary2',
    tabButtonActiveText: '$textSecondary',
    tabButtonActiveBackground: '$backgroundSecondary',

    // Brand Card (märken)
    brandCardPrimaryBackgroundColor: '$secondary2',
    brandCardPrimaryHoverBackgroundColor: '$secondary4',
    brandCardSecondaryBackgroundColor: '$primary3',
    brandCardSecondaryHoverBackgroundColor: '$primary4',

    ///// ------------ MOLECULES ---------- /////

    // Footer Brand Area
    brandAreaBackground: '$primary3',

    //DropDowns
    dropdownHeaderBackground: '$primary3',
    dropdownChildrenBackground: '$backgroundPrimary',
    dropdownText: '$textPrimary',
    dropdownBorder: '$primary3',
    dropdownCounter: '$primaryLight3',

    //Breadcrumbs
    breadcrumbBackgroundPrimary: '$backgroundPrimary',
    breadcrumbActiveLink: '$primaryLight3',

    //CollapsableSection
    collapsableText: '$textPrimary',
    collapsableHeaderBackground: '$primary3',
    collapsableContentBackground: '$primary4',
    collapsableHoverBackground: '$primary4',

    //List and Listitems
    listItemBackgroundPrimary: '$grey200',

    //Filter modal
    filterModalButtonBackground: '$primary3',

    //Payment information
    paymentInfoTextPrimary: '$textPrimary',
    paymentInfoBackgroundPrimary: '$backgroundPrimary',
    paymentInfoButtonBackgroundPrimary: '$accent',

    //Filterbar
    filterbarBackground: '$grey1000',

    // ------- Organisms ---------

    // Carousel Image Slideshow
    carouselImageBackground: '$pureWhite',

    // Modal
    modalContentBackground: '$backgroundPrimary',
    modalContentBorder: '$primary3',

    modalOverlayPrimary: 'rgba(14, 14, 16, 0.5)', // primary6 with opacity 0.5
    modalOverlaySecondary: 'rgba(41, 42, 49, 0.5)', // primary2 with opacity 0.5

    //Cart
    cartItemBackgroundPrimary: '$grey1000',
    cartItemText: '$textPrimary',

    //Footer
    footerBackgroundPrimary: '$backgroundSecondary',
    footerTextPrimary: '$white',

    //Hero
    heroTextPrimary: '$pureWhite',

    //Product card
    productCardBorderPrimary: '$grey1100',
    productCardTextPrimary: '$textPrimary',

    //New card
    newsCardBackgroundPrimary: '$backgroundPrimary',
    newsCardTextPrimary: '$textPrimary',

    //Content result
    contentResultBackgroundPrimary: '$backgroundPrimary',
    contentResultTextPrimary: '$textPrimary',

    //QuickSearch
    searchBackgroundPrimary: '$primary5',
    mobileSearchBorderPrimary: '$JE68GreenPrimary',
    quickSearchTextPrimary: '$textPrimary',
    quickSearchOutline: '$secondary2',
    quickSearchOutlineNoValue: '$primary3',

    //Menu
    menuBackgroundPrimary: '$backgroundPrimary',

    // Site header
    siteHeaderDesktopBackground: '$primary5',
    siteHeaderDesktopBackgroundLower: '$primary4',
    siteHeaderMobileBackground: '$primary5',
    siteHeaderLinkColor: '$textPrimary',
    siteHeaderLinkUnderline: '$JE68GreenPrimary',

    //Navigation
    navigationBackgroundPrimary: '$backgroundPrimary',
    navigationTextPrimary: '$textPrimary',
    navigationBackgroundSecondary: '$primary3',

    //Blocks

    //Video block
    videoBlockBackground: '$grey1100',
    videoBlockBorderBackground: '$grey900',
    videoBlockYoutubeBackground: '$accent',

    //Icon block
    iconBlockTextPrimary: '$textPrimary',

    //Pages

    //Product page
    productPageBackgroundPrimary: '$grey1100',
    productDescriptionTextPrimary: '$textPrimary',
    productDescriptionTextSecondary: '$textSecondary',
    productPageRelatedProductBackground: '$grey1000',
    productPageNewPrice: '$JE68GreenPrimary',
    productPagePrice: '$textPrimary',

    //Cart page
    cartContentTextPrimary: '$textPrimary',
    cartMainBackgroundPrimary: '$backgroundPrimary',
    cartArticlesBackgroundPrimary: '$backgroundPrimary',

    //News archive page
    newsArchivePageBackgroundPrimary: '$backgroundPrimary',
    newsArchivePageTextPrimary: '$textPrimary',

    //New page
    newsPageBackgroundPrimary: '$backgroundPrimary',
    newsPageTextPrimary: '$textPrimary',

    //Account page
    accountInfoGridBoxBackgroundPrimary: '$grey1100',
    accountFormGridBoxBackgroundPrimary: '$grey1000',
    accountTextPrimary: '$textPrimary',
    accountLinkButtonBackgroundPrimary: '$buttonPrimary',
    accountCreateAccountButtonBackgroundPrimary: '$buttonPrimary',
    accountCreateAccountButtonTextPrimary: '$textSecondary',

    //Search page
    searchPageTextPrimary: '$textPrimary',
    searchPageSecondaryPrimary: '$textPrimary',
    searchPageBorderPrimary: '$grey700',
    searchBackgroundColor: '$primary4',
    noResultBackgroundColor: '$primary3',

    //Order confirmation page
    orderConfirmationPageBackgroundPrimary: '$backgroundPrimary',
    orderConfirmationPageText: '$textPrimary',

    //Checkout page
    checkoutPageBackgroundPrimary: '$backgroundPrimary',
    checkoutPageBackgroundSecondary: '$primary4',
    checkoutPageText: '$textPrimary',

    campaignLabelBackground: '$JE68GreenPrimary',
    campaignLabelText: '$primary4',

    priceText: '$textPrimary',
    loweredPriceText: '$JE68GreenPrimary',
  },
});

export default dark;
