import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function BagIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M11.4286 6.85715H20.5715C21.7839 6.85715 22.9466 7.33878 23.804 8.19609C24.6613 9.0534 25.1429 10.2162 25.1429 11.4286V30.8571H6.85718V11.4286C6.85718 10.2162 7.33881 9.0534 8.19612 8.19609C9.05343 7.33878 10.2162 6.85715 11.4286 6.85715V6.85715Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M6.85711 30.8571H1.14282V21.7143C1.14282 21.1081 1.38364 20.5267 1.81229 20.098C2.24095 19.6694 2.82233 19.4286 3.42854 19.4286H6.85711"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M25.1428 19.4286H28.5714C29.1776 19.4286 29.759 19.6694 30.1876 20.098C30.6163 20.5267 30.8571 21.1081 30.8571 21.7143V30.8571H25.1428"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M6.85718 17.1429H25.1429"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M16 14.8571V19.4286"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M11.4285 6.85714V5.71428C11.4285 4.50186 11.9101 3.3391 12.7674 2.48179C13.6247 1.62448 14.7875 1.14285 15.9999 1.14285C17.2123 1.14285 18.3751 1.62448 19.2324 2.48179C20.0897 3.3391 20.5713 4.50186 20.5713 5.71428V6.85714"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default BagIcon;
