import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function BackIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M3.00195 12.5515H21.0575C22.8993 12.5515 24.6656 13.2832 25.968 14.5855C27.2703 15.8878 28.002 17.6542 28.002 19.496C28.002 21.3377 27.2703 23.1041 25.968 24.4064C24.6656 25.7088 22.8993 26.4404 21.0575 26.4404"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M8.55751 6.99609L3.00195 12.5516L8.55751 18.1072"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default BackIcon;
