export const BaseParagraphStyle = {
  color: '$textPrimary',
  variants: {
    size: {
      xs: {
        fs: 6,
        lineHeight: '$lh133',
      },
      s: {
        fs: 8,
        lineHeight: '$lh15',
      },
      l: {
        fs: 9,
        lineHeight: '$lh155',
      },
    },
  },
  defaultVariants: {
    size: 's',
  },
};
