import React from 'react';
import AppTranslations from '../../../Models/Translations/AppTranslations.interface';

type PropType = {
  children: React.ReactNode;
  data: AppTranslations;
};

const initState = {} as AppTranslations;

const TranslationContext = React.createContext(initState);

export const TranslationProvider = ({ children, data }: PropType) => {
  return (
    <TranslationContext.Provider value={data}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationData = () => {
  return React.useContext(TranslationContext) as AppTranslations;
};
