import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function SuspensionIcon({
  css,
  color,
  fillColor,
  size,
  space,
  onBackground,
}: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M27 9C29.2091 9 31 7.20914 31 5C31 2.79086 29.2091 1 27 1C24.7909 1 23 2.79086 23 5C23 7.20914 24.7909 9 27 9Z"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M5 31C7.20914 31 9 29.2091 9 27C9 24.7909 7.20914 23 5 23C2.79086 23 1 24.7909 1 27C1 29.2091 2.79086 31 5 31Z"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M23.08 6.07999L10.74 18.44"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M25.9201 8.92001L13.5601 21.26"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <StyledPath
        onBackground={onBackground}
        d="M27 6C27.5523 6 28 5.55228 28 5C28 4.44772 27.5523 4 27 4C26.4477 4 26 4.44772 26 5C26 5.55228 26.4477 6 27 6Z"
        fillColor={fillColor}
      />
      <StyledPath
        onBackground={onBackground}
        d="M5 28C5.55228 28 6 27.5523 6 27C6 26.4477 5.55228 26 5 26C4.44772 26 4 26.4477 4 27C4 27.5523 4.44772 28 5 28Z"
        fillColor={fillColor}
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M9.2876 17.0577L14.9445 22.7145L12.116 25.543C11.741 25.918 11.2322 26.1287 10.7018 26.1287C10.1714 26.1287 9.66267 25.918 9.2876 25.543L6.45917 22.7145C6.0841 22.3395 5.87338 21.8308 5.87338 21.3003C5.87338 20.7699 6.0841 20.2612 6.45917 19.8861L9.2876 17.0577Z"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M11.4399 14.9L18.5199 19.14"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M13.8 12.54L20.88 16.78"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M16.1599 10.18L23.2199 14.42"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M18.52 7.82001L25.58 12.08"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default SuspensionIcon;
