import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function HeartIcon({
  css,
  size,
  color,
  space,
  fillColor = 'transparent',
  onBackground,
}: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        fillColor={fillColor}
        d="M16.0019 29.1253L3.2179 15.792C2.09435 14.6693 1.35278 13.2214 1.09838 11.6536C0.843977 10.0858 1.08965 8.47767 1.80056 7.05733V7.05733C2.33664 5.98549 3.11972 5.05636 4.08529 4.34651C5.05086 3.63666 6.17128 3.16639 7.35424 2.97446C8.53719 2.78254 9.74882 2.87444 10.8893 3.24259C12.0298 3.61075 13.0664 4.24463 13.9139 5.092L16.0019 7.17866L18.0899 5.092C18.9374 4.24463 19.974 3.61075 21.1145 3.24259C22.255 2.87444 23.4666 2.78254 24.6496 2.97446C25.8325 3.16639 26.9529 3.63666 27.9185 4.34651C28.8841 5.05636 29.6672 5.98549 30.2032 7.05733C30.9132 8.47712 31.1584 10.0843 30.9043 11.6512C30.6502 13.2181 29.9095 14.6654 28.7872 15.788L16.0019 29.1253Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default HeartIcon;
