import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function FuseIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M2.70701 17.9783L1.2928 19.3926C0.902276 19.7831 0.902276 20.4163 1.2928 20.8068L11.1923 30.7063C11.5828 31.0968 12.216 31.0968 12.6065 30.7063L14.0207 29.2921C14.4112 28.9015 14.4112 28.2684 14.0207 27.8778L4.12123 17.9783C3.7307 17.5878 3.09754 17.5878 2.70701 17.9783Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M19.3938 1.29372L17.9796 2.70793C17.589 3.09846 17.589 3.73162 17.9796 4.12215L27.8791 14.0216C28.2696 14.4122 28.9028 14.4122 29.2933 14.0216L30.7075 12.6074C31.098 12.2169 31.098 11.5837 30.7075 11.1932L20.808 1.29372C20.4175 0.903195 19.7843 0.903195 19.3938 1.29372Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M18.6855 4.82866L4.82812 18.6861L13.3134 27.1713L27.1708 13.3139L18.6855 4.82866Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M22.9292 9.07065L19.4599 12.54L18.7532 16.076H13.0959L12.3892 19.6106L9.07056 22.9293"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default FuseIcon;
