import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function ReturnIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M20.5737 1.14285L26.288 6.85714L20.5737 12.5714"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M1.14502 13.7143V9.14286C1.14502 8.53665 1.38584 7.95527 1.81449 7.52662C2.24314 7.09796 2.82452 6.85715 3.43073 6.85715H26.2879"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M11.4306 30.8571L5.71631 25.1428L11.4306 19.4286"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M30.8592 18.2857V22.8571C30.8592 23.4633 30.6183 24.0447 30.1897 24.4734C29.761 24.902 29.1797 25.1428 28.5735 25.1428H5.71631"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default ReturnIcon;
