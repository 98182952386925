import React from 'react';
import {
  hasFileExtension,
  isEmailLink,
  isInternalLink,
} from 'Shared/Common/Helpers';
import { styled } from 'stitches.config';
import { CSS } from '@stitches/react';

import { KexNavigateType, useKexNavigate } from '../Kex/KexRouter/KexRouter';
import { KexLinkBase } from './BaseKexLinkStyle';

type RedirectType = {
  kexNavigate: KexNavigateType;
  target: string;
  url: string;
  onNavigated?: () => void;
  clearQueryString?: boolean;
};

type PropType = {
  onClick?: (x?: () => void) => void;
  callbackOnClick?: boolean;
  onNavigated?: () => void;
  children?: React.ReactNode;
  href?: string;
  title?: string;
  css?: CSS;
  fontFamily?: 'primary400' | 'primary500' | 'secondary400' | 'secondary500';
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  clearQueryString?: boolean;
  underlined?: boolean;
};

const redirect = ({
  kexNavigate,
  target,
  url = '',
  onNavigated,
  clearQueryString,
}: RedirectType) => {
  if (url === '' || url === undefined) return;
  if (
    target !== '_blank' &&
    isInternalLink({ url }) &&
    !hasFileExtension({ url })
  ) {
    onNavigated
      ? kexNavigate(url, undefined, clearQueryString).then(() => onNavigated())
      : kexNavigate(url, undefined, clearQueryString);
  } else if (isEmailLink({ url })) {
    window.location.href = url;
  } else {
    window.open(url, '_blank');
  }
};

const KexLink = ({
  onClick,
  callbackOnClick,
  onNavigated,
  children,
  href,
  title,
  css,
  fontFamily,
  clearQueryString,
  underlined = true,
  ...rest
}: PropType) => {
  const kexNavigate = useKexNavigate();
  return typeof href === 'string' && href.substr(0, 4) === 'http' ? (
    <StyledA
      aria-label={title}
      {...rest}
      target="blank"
      href={href}
      fontFamily={fontFamily}
      underlined={underlined}
    >
      {children}
    </StyledA>
  ) : (
    <StyledA
      aria-label={title}
      css={css}
      {...rest}
      onClick={(e) => {
        e.persist();
        e.preventDefault();
        const target = e.currentTarget.target;
        if (callbackOnClick) {
          onClick &&
            onClick(() =>
              redirect({
                kexNavigate,
                target,
                url: href ?? '',
                onNavigated,
                clearQueryString,
              })
            );
        } else {
          onClick && onClick();
          redirect({
            kexNavigate,
            target,
            url: href ?? '',
            onNavigated,
            clearQueryString,
          });
        }
      }}
      href={href}
      fontFamily={fontFamily}
      underlined={underlined}
    >
      {children}
    </StyledA>
  );
};

const StyledA = styled(KexLinkBase, {});

export default KexLink;
