import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function FullscreenIcon({
  css,
  color,
  size,
  space,
  onBackground,
}: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M31.0068 10V2.996C31.0068 2.46557 30.7961 1.95686 30.4211 1.58179C30.046 1.20672 29.5373 0.996002 29.0068 0.996002H22.0068"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M22.0068 30.996H29.0068C29.5373 30.996 30.046 30.7853 30.4211 30.4102C30.7961 30.0351 31.0068 29.5264 31.0068 28.996V22"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M1.00684 22V29C1.00684 29.5304 1.21755 30.0391 1.59262 30.4142C1.9677 30.7893 2.4764 31 3.00684 31H10.0068"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M10.0068 0.996002H3.00684C2.4764 0.996002 1.9677 1.20672 1.59262 1.58179C1.21755 1.95686 1.00684 2.46557 1.00684 2.996V10"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default FullscreenIcon;
