import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

type PropTypes = IconBaseType & {
  rotateLeft?: boolean;
};

function ArrowIcon({
  css,
  size,
  space,
  color,
  rotateLeft,
  onBackground,
}: PropTypes) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      rotateLeft={rotateLeft}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8591 16H1.14478"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        onBackground={onBackground}
        color={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.14478 8L1.14478 16L9.14478 24"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {
  variants: {
    rotateLeft: {
      true: {
        transform: 'rotate(180deg)',
      },
    },
  },
});
const StyledPath = styled(BaseIconPathStyle, {});

export default ArrowIcon;
