import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function HouseIcon({ css, color, size, onBackground }: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      size={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        fillRule="nonzero"
        d="M21.002 31V21C21.002 20.4696 20.7913 19.9609 20.4162 19.5858C20.0411 19.2107 19.5324 19 19.002 19H13.002C12.4716 19 11.9629 19.2107 11.5878 19.5858C11.2127 19.9609 11.002 20.4696 11.002 21V31H2.30868C1.95505 31 1.61591 30.8595 1.36587 30.6095C1.11582 30.3594 0.975342 30.0203 0.975342 29.6667V13C1.02147 12.6116 1.21182 12.2547 1.50868 12L16.002 1L21.3353 5V1.66667H27.3353V9.54667L30.4953 12C30.7975 12.2754 30.9794 12.6585 31.002 13.0667V29.6933C31.002 30.047 30.8615 30.3861 30.6115 30.6361C30.3614 30.8862 30.0223 31.0267 29.6687 31.0267L21.002 31Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default HouseIcon;
