import KexLink from 'Kex/KexLink';
import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';

function Breadcrumb() {
  const { breadcrumb } = useCurrentPage<PageModelBase>();
  const { languageRoute } = useAppSettingsData();
  const { commonLabels } = useTranslationData();

  if (breadcrumb.length === 1) return <></>;

  return (
    <BreadcrumbDiv>
      <BreadcrumbContainer>
        <BreadcrumbLinkWrapper>
          <BreadcrumbLink href={`/${languageRoute}`} fontFamily="primary400">
            {commonLabels.home}
          </BreadcrumbLink>
        </BreadcrumbLinkWrapper>
        <Seperator>/</Seperator>
        {breadcrumb.map((link, index) => (
          <BreadcrumbLinkWrapper key={index + link.id}>
            <BreadcrumbLink
              href={link.href}
              normal={index === breadcrumb.length - 1}
              fontFamily="primary400"
              underlined={false}
            >
              {link.text}
            </BreadcrumbLink>
            {index !== breadcrumb.length - 1 && <Seperator>/</Seperator>}
          </BreadcrumbLinkWrapper>
        ))}
      </BreadcrumbContainer>
    </BreadcrumbDiv>
  );
}

const BreadcrumbDiv = styled('div', {
  h: 12,
  w: '100%',
  WebkitOverflowScrolling: 'touch',
  overflowX: 'auto',
  alignItems: 'center',
  display: 'flex',
  backgroundColor: '$breadcrumbBackgroundPrimary',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '@mediaMaxSmall': {
    mt: 1,
  },
});

const BreadcrumbLinkWrapper = styled('div', {
  display: 'inline-flex',
});

const BreadcrumbContainer = styled('div', {
  w: '$screenMaxWidth',
  h: 4,
  mx: 'auto',
  display: 'flex',
  color: '$secondary2',
});

const BreadcrumbLink = styled(KexLink, {
  fs: 6,
  color: '$secondary2',
  whiteSpace: 'nowrap',
  variants: {
    normal: {
      true: {
        color: '$breadcrumbActiveLink',
      },
    },
  },
});

const Seperator = styled('span', {
  fs: 6,
  my: 'auto',
  fw: '$fw400',
  textDecoration: 'none',
  paddingLeft: '8px',
  px: 2,
});

export default Breadcrumb;
