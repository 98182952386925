import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function LogoIcon({
  css,
  fillColor,
  size,
  space,
  onBackground,
}: Omit<IconBaseType, 'color'>) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 76 19">
      <g clipPath="url(#clip0_1891_5671)">
        <StyledPath
          onBackground={onBackground}
          d="M9.0602 18.9088C11.6366 18.9088 13.6525 18.2755 15.1082 17.0088C16.5638 15.7421 17.2925 13.9774 17.2945 11.7147V1.4941C17.2998 1.45941 17.297 1.42395 17.2862 1.39054C17.2754 1.35713 17.257 1.32668 17.2323 1.30159C17.2077 1.27649 17.1775 1.25743 17.1442 1.24592C17.1109 1.23441 17.0754 1.23077 17.0404 1.23527H13.7047C13.5363 1.23527 13.4536 1.32057 13.4536 1.4941V11.2C13.4756 11.716 13.391 12.231 13.205 12.7131C13.019 13.1953 12.7356 13.6343 12.3722 14.0029C11.6513 14.7127 10.5089 15.0676 8.94497 15.0676H8.64952C6.97724 15.0676 5.80429 14.7353 5.13065 14.0706C4.45702 13.4059 4.11922 12.448 4.11725 11.197V10.0382C4.11725 9.86469 4.03452 9.7794 3.86611 9.7794H0.438833C0.403871 9.77489 0.368339 9.77853 0.335034 9.79004C0.30173 9.80155 0.271564 9.82059 0.246924 9.84569C0.222284 9.87079 0.203842 9.90125 0.19305 9.93466C0.182259 9.96807 0.179414 10.0035 0.184742 10.0382V11.7265C0.184742 14.0108 0.90959 15.7755 2.35929 17.0206C3.80898 18.2657 5.85944 18.8912 8.51065 18.897L9.0602 18.9088Z"
          fillColor={fillColor}
        />
        <StyledPath
          onBackground={onBackground}
          d="M42.3522 8.77058C42.429 7.3294 42.9431 6.21763 43.8944 5.43528C44.9182 4.62647 46.1989 4.20915 47.5049 4.2588C49.8941 4.2588 51.1744 4.98723 51.3458 6.4441C51.3753 6.64704 51.4669 6.73821 51.6206 6.73821H55.5087C55.6919 6.73821 55.7776 6.63822 55.7628 6.4441C55.3453 4.57939 54.5052 3.18626 53.2426 2.26469C51.98 1.34312 50.0675 0.881355 47.5049 0.879395C44.7887 0.879395 42.622 1.66371 41.0049 3.23234C39.3878 4.80096 38.5753 7.15979 38.5674 10.3088C38.5674 13.1735 39.2568 15.3215 40.6356 16.7529C42.0144 18.1843 44.2037 18.9 47.2035 18.9C49.7799 18.8863 51.8343 18.3617 53.3667 17.3265C54.0978 16.8674 54.6987 16.2295 55.1119 15.4737C55.5252 14.7179 55.737 13.8693 55.7274 13.0088C55.7274 11.1951 55.0912 9.81468 53.8187 8.86762C52.5463 7.92057 50.6406 7.43822 48.1017 7.42057C45.4347 7.42057 43.5182 7.86665 42.3522 8.7588V8.77058ZM44.0422 14.85C43.2681 14.4265 42.881 13.8314 42.881 13.0647C42.881 11.4529 44.4065 10.647 47.4576 10.647C50.4122 10.647 51.8845 11.4313 51.8746 13C51.8746 14.6255 50.4023 15.4549 47.4576 15.4882C45.9567 15.4882 44.8182 15.2774 44.0422 14.8559V14.85Z"
          fillColor={fillColor}
        />
        <StyledPath
          onBackground={onBackground}
          d="M37.0044 18.5794C37.039 18.5839 37.0741 18.5804 37.1071 18.569C37.14 18.5576 37.1699 18.5388 37.1943 18.514C37.2186 18.4891 37.2368 18.459 37.2475 18.4259C37.2581 18.3929 37.2609 18.3578 37.2555 18.3235V15.3823C37.2608 15.3479 37.2579 15.3128 37.2473 15.2796C37.2367 15.2464 37.2185 15.2162 37.1942 15.1911C37.1699 15.1661 37.1402 15.147 37.1073 15.1353C37.0743 15.1235 37.0391 15.1195 37.0044 15.1235H23.8951V11.5147H34.5314C34.5661 11.5191 34.6013 11.5155 34.6344 11.5041C34.6674 11.4928 34.6974 11.474 34.722 11.4492C34.7466 11.4245 34.7651 11.3944 34.7761 11.3614C34.7871 11.3284 34.7904 11.2933 34.7855 11.2588V8.34705C34.7909 8.31235 34.788 8.27689 34.7772 8.24348C34.7664 8.21007 34.748 8.17961 34.7234 8.15452C34.6987 8.12942 34.6685 8.11037 34.6352 8.09887C34.6019 8.08736 34.5664 8.08372 34.5314 8.08822H23.8951V4.66174H36.9571C36.9921 4.66625 37.0276 4.6626 37.0609 4.6511C37.0942 4.63959 37.1244 4.62054 37.149 4.59544C37.1737 4.57034 37.1921 4.53988 37.2029 4.50647C37.2137 4.47306 37.2165 4.43761 37.2112 4.40292V1.4941C37.2165 1.45941 37.2137 1.42395 37.2029 1.39054C37.1921 1.35713 37.1737 1.32668 37.149 1.30159C37.1244 1.27649 37.0942 1.25743 37.0609 1.24592C37.0276 1.23441 36.9921 1.23077 36.9571 1.23527H20.3053C20.1369 1.23527 20.0542 1.32057 20.0542 1.4941V18.3235C20.0488 18.3578 20.0516 18.3929 20.0622 18.4259C20.0729 18.459 20.0911 18.4891 20.1154 18.514C20.1398 18.5388 20.1697 18.5576 20.2026 18.569C20.2356 18.5804 20.2707 18.5839 20.3053 18.5794H37.0044Z"
          fillColor={fillColor}
        />
        <StyledPath
          onBackground={onBackground}
          d="M66.9102 18.9324C72.6736 18.9324 75.5562 17.0784 75.5582 13.3706V13.3471C75.5679 12.57 75.369 11.8044 74.982 11.1294C74.6438 10.5072 74.122 10.0031 73.487 9.68531C74.0756 9.38028 74.5508 8.89591 74.8432 8.30295C75.1895 7.64054 75.3642 6.90254 75.3514 6.1559V6.13236C75.3514 2.62648 72.5219 0.873535 66.863 0.873535H66.5409C60.9292 0.873535 58.1234 2.63824 58.1234 6.16765V6.19119C58.1127 6.92871 58.2831 7.65766 58.6198 8.31471C58.9066 8.90539 59.3784 9.3874 59.9641 9.68825C59.3237 10.011 58.8009 10.5252 58.4691 11.1588C58.0857 11.8352 57.887 12.5999 57.893 13.3765V13.4C57.893 17.0922 60.7756 18.9382 66.5409 18.9382L66.9102 18.9324ZM63.1609 7.78237C62.44 7.4706 62.0795 6.97648 62.0795 6.31178V6.28824C62.0795 5.01962 63.6287 4.3853 66.727 4.3853C69.8254 4.3853 71.3814 5.0353 71.3952 6.3353V6.35884C71.3952 7.00001 71.0289 7.47354 70.302 7.77648C69.5752 8.07942 68.3816 8.24412 66.727 8.25883C65.0725 8.27353 63.8818 8.10295 63.1609 7.78825V7.78237ZM61.8491 13.3471V13.3235C61.8491 12.6824 62.2302 12.2147 62.9895 11.9265C63.7489 11.6382 64.9868 11.4912 66.7034 11.4912C68.42 11.4912 69.658 11.6412 70.4291 11.9382C71.2002 12.2353 71.6109 12.7118 71.6109 13.3706V13.3941C71.6109 14.051 71.2278 14.5412 70.4616 14.8647C69.6954 15.1882 68.4505 15.3539 66.727 15.3618C63.4751 15.3618 61.8491 14.6892 61.8491 13.3441V13.3471Z"
          fillColor={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1891_5671">
          <rect
            width="75.3764"
            height="18.05"
            fill="white"
            transform="translate(0.181885 0.882324)"
          />
        </clipPath>
      </defs>
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default LogoIcon;
