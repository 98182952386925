import { useState, useEffect, useCallback } from 'react';
import { canUseDOM } from 'Shared/Common/Helpers';
import { SizeConstants } from 'Theme/Settings/sizes';

const headerHeight = SizeConstants.desktopHeaderHeight;

const useHideHeader = () => {
  const [isSticky, setSticky] = useState(false);
  const [previousOffsetY, setPreviousOffSetY] = useState(0);

  const isDOMAvailable = canUseDOM();

  const handleScroll = useCallback(() => {
    const offsetY = window.pageYOffset;
    // when scrolling up
    if (offsetY <= previousOffsetY) {
      const weAreBelowHeader = offsetY >= headerHeight;
      // trigger sticky when scrolled down below the header
      setSticky(weAreBelowHeader);
    } else {
      setSticky(false);
    }

    // set previous scroll top to later calculate if we had scrolled up or down
    const weAreAboveHeader = offsetY <= headerHeight + 10;
    setPreviousOffSetY(weAreAboveHeader ? 0 : offsetY);
  }, [previousOffsetY]);

  useEffect(() => {
    if (!isDOMAvailable) {
      return;
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isDOMAvailable, handleScroll]);

  return isSticky;
};

export default useHideHeader;
