import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function CreditCardIcon({ css, color, size, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} viewBox="0 0 32 32">
      <StyledPath
        d="M29.002 5H3.00195C1.89738 5 1.00195 5.89543 1.00195 7V25C1.00195 26.1046 1.89738 27 3.00195 27H29.002C30.1065 27 31.002 26.1046 31.002 25V7C31.002 5.89543 30.1065 5 29.002 5Z"
        color={color}
        onBackground={onBackground}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        d="M1.00195 11H31.002"
        color={color}
        onBackground={onBackground}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        d="M7.00195 17H18.002"
        color={color}
        onBackground={onBackground}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        d="M7.00195 21H14.002"
        color={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default CreditCardIcon;
