import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function BankidIcon({
  css,
  size,
  space,
  fillColor,
  onBackground,
}: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M10.0979 16.6961L11.4348 7.78581C10.9082 7.78581 10.0067 7.78581 10.0067 7.78581C9.33829 7.78581 8.47739 7.38955 8.22419 6.66131C8.14316 6.41499 7.95073 5.56894 9.0547 4.74431C9.4497 4.45515 9.7029 4.13386 9.75354 3.88755C9.80418 3.63052 9.74341 3.40562 9.57124 3.23427C9.32816 2.98795 8.85213 2.84872 8.24444 2.84872C7.2215 2.84872 6.50239 3.46988 6.4315 3.91967C6.38086 4.25167 6.62393 4.51941 6.83662 4.69076C7.4747 5.19411 7.62662 5.92235 7.23162 6.60776C6.8265 7.31459 5.94534 7.7751 5.00342 7.78581C5.00342 7.78581 4.07163 7.78581 3.54496 7.78581C3.42343 8.65328 1.4383 21.9545 1.28638 23.004H9.16611C9.23701 22.5435 9.60162 20.0268 10.0979 16.6961Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M0.860896 26.0883H4.09179C5.46922 26.0883 5.80345 26.8273 5.70217 27.502C5.62114 28.0482 5.26666 28.4551 4.65897 28.7229C5.42871 29.0335 5.73255 29.5154 5.62114 30.2758C5.47935 31.2289 4.69948 31.9357 3.67653 31.9357H0L0.860896 26.0883ZM2.99794 28.5087C3.62589 28.5087 3.91961 28.1553 3.98038 27.7376C4.04115 27.2878 3.84871 26.9772 3.22076 26.9772H2.66371L2.44089 28.5087H2.99794ZM2.65358 31.0254C3.30179 31.0254 3.67653 30.747 3.76769 30.1794C3.83858 29.6867 3.57525 29.3976 2.9473 29.3976H2.31935L2.07628 31.0361H2.65358V31.0254Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M10.1526 31.9786C9.31195 32.0428 8.90682 31.9465 8.70426 31.5609C8.25862 31.8501 7.76234 32 7.23567 32C6.28362 32 5.94939 31.4752 6.04054 30.8969C6.08106 30.6185 6.23298 30.3507 6.47606 30.1258C7.00272 29.6439 8.29913 29.5797 8.80554 29.2155C8.84605 28.8086 8.69413 28.6586 8.2181 28.6586C7.66105 28.6586 7.19516 28.8514 6.39503 29.4297L6.58747 28.1017C7.27618 27.577 7.94464 27.3307 8.71439 27.3307C9.69682 27.3307 10.5678 27.759 10.4058 28.8942L10.2134 30.1794C10.1425 30.6292 10.1627 30.7684 10.6387 30.7791L10.1526 31.9786ZM8.69413 29.9545C8.24849 30.2544 7.41798 30.2008 7.32682 30.822C7.28631 31.1111 7.45849 31.3253 7.73195 31.3253C7.99528 31.3253 8.31939 31.2075 8.58272 31.0147C8.56246 30.9076 8.57259 30.8005 8.60298 30.5971L8.69413 29.9545Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M11.7157 27.4056H13.397L13.3058 27.9946C13.8426 27.5127 14.2478 27.3307 14.7744 27.3307C15.7163 27.3307 16.1519 27.9411 15.9999 28.9371L15.5644 31.925H13.8831L14.2478 29.4511C14.3186 29.0013 14.187 28.7871 13.8629 28.7871C13.5995 28.7871 13.3565 28.9371 13.1235 29.2691L12.7387 31.9143H11.0574L11.7157 27.4056Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M17.3245 26.0883H19.0058L18.5804 28.9585L20.1908 27.4056H22.2671L20.2009 29.3333L21.8619 31.925H19.7452L18.469 29.826H18.4487L18.1449 31.9143H16.4636L17.3245 26.0883Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M23.2675 26.0883H25.202L24.3512 31.925H22.4167L23.2675 26.0883Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M26.1421 26.0883H28.9071C31.0442 26.0883 31.662 27.7269 31.4594 29.087C31.267 30.415 30.2744 31.925 28.4007 31.925H25.2812L26.1421 26.0883ZM27.9348 30.5328C28.8768 30.5328 29.3933 30.0402 29.5452 29.0013C29.6566 28.2302 29.4338 27.4699 28.3906 27.4699H27.8741L27.4284 30.5328H27.9348Z"
      />
      <StyledPath
        onBackground={onBackground}
        fillColor={fillColor}
        d="M20.688 0H12.6361L11.5625 7.2075H12.9298C13.6793 7.2075 14.3883 6.84337 14.6921 6.31861C14.7934 6.14726 14.8339 5.99732 14.8339 5.8581C14.8339 5.55823 14.6415 5.33333 14.449 5.1834C13.9224 4.76573 13.811 4.32664 13.811 4.01606C13.811 3.95181 13.811 3.89826 13.8211 3.84471C13.9325 3.08434 14.9048 2.25971 16.1911 2.25971C16.9608 2.25971 17.5483 2.45248 17.9027 2.80589C18.2167 3.11647 18.3383 3.55556 18.2471 4.01606C18.1357 4.56225 17.6192 5.01205 17.3254 5.23695C16.5456 5.81526 16.6469 6.31861 16.6975 6.46854C16.8595 6.91834 17.4774 7.2075 17.9534 7.2075H20.0398C20.0398 7.2075 20.0398 7.2075 20.0398 7.21821C22.8757 7.23963 24.3949 8.62115 23.9189 11.834C23.4732 14.822 21.3058 16.1071 18.7231 16.1285L17.7002 23.0254H19.2093C25.5799 23.0254 30.7858 18.6988 31.8087 11.8661C33.0748 3.3842 27.9803 0 20.688 0Z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default BankidIcon;
