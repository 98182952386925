export const BaseListStyle = {
  listStyle: 'none',
  margin: 0,
  padding: 0,
};

export const BaseListItemStyle = {
  fs: 8,
  lineHeight: '$lh175',
  color: '$textPrimary',
  '@mediaMaxSmall': {
    fs: 7,
    lineHeight: '$lh15',
  },
};
