import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function TshirtIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M24 3.42856L30.8571 10.2857L26.2857 14.8571L24 12.5714V26.2857C24 26.8919 23.7591 27.4733 23.3305 27.9019C22.9018 28.3306 22.3205 28.5714 21.7142 28.5714H10.2857C9.67947 28.5714 9.09809 28.3306 8.66943 27.9019C8.24078 27.4733 7.99996 26.8919 7.99996 26.2857V12.5714L5.71425 14.8571L1.14282 10.2857L7.99996 3.42856H24Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default TshirtIcon;
