import PageLoader from 'Atoms/Loaders/PageLoader';
import React, { useReducer } from 'react';

type PropType = {
  children: React.ReactNode;
};

type LoadingState = Set<string>;

type LoadingActionType =
  | { type: 'add'; from: string }
  | { type: 'remove'; from: string };

type LoadingContextType = [LoadingState, React.Dispatch<LoadingActionType>];

const KexLoadingDispatchContext = React.createContext<LoadingContextType>([
  new Set(),
  () => {},
]);

const reducer = (state: LoadingState, action: LoadingActionType) => {
  switch (action.type) {
    case 'add': {
      const mp = new Set(state);
      mp.add(action.from);
      return mp;
    }
    case 'remove': {
      const mp = new Set(state);
      mp.delete(action.from);
      return mp;
    }
    default: {
      console.warn(`Unknown dispatch action + ${action}`);
      return state;
    }
  }
};

function KexLoadingCircle({ children }: PropType) {
  const result = useReducer(reducer, new Set<string>());

  return (
    <KexLoadingDispatchContext.Provider value={result}>
      <PageLoader
        loading={Array.from(result[0].values()).findIndex((s) => s) >= 0}
      />
      {children}
    </KexLoadingDispatchContext.Provider>
  );
}

const useKexLoadingCircle = (): LoadingContextType =>
  React.useContext(KexLoadingDispatchContext);

export { KexLoadingCircle, useKexLoadingCircle };
