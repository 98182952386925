import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function ElectricIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M23.7974 15.0747C23.9245 14.868 23.9942 14.6312 23.9993 14.3886C24.0044 14.1461 23.9447 13.9065 23.8263 13.6947C23.708 13.4829 23.5353 13.3065 23.3261 13.1837C23.1168 13.0609 22.8786 12.9961 22.636 12.996H18V0.996002L8.20269 16.9187C8.07546 17.1252 8.00568 17.362 8.00055 17.6045C7.99542 17.847 8.05512 18.0865 8.17349 18.2983C8.29187 18.51 8.46462 18.6863 8.67391 18.809C8.8832 18.9316 9.12144 18.9962 9.36402 18.996H14V30.996L23.7974 15.0747Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default ElectricIcon;
