import { CSS } from '@stitches/react';
import React from 'react';
import { styled } from 'stitches.config';

import { BaseParagraphStyle } from './BaseParagraphStyle';

type ParagraphProps = {
  children: React.ReactNode;
  size?: 'xs' | 's' | 'l';
  css?: CSS;
};

const Paragraph = ({ children, size, css }: ParagraphProps) => (
  <StyledParagraph size={size} css={css}>
    {children}
  </StyledParagraph>
);

const StyledParagraph = styled('p', BaseParagraphStyle);

export default Paragraph;
