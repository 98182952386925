import { styled } from 'stitches.config';
import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

type PropTypes = IconBaseType & {
  rotateLeft?: boolean;
  rotateDown?: boolean;
  transition?: boolean;
};

function ChevronIcon({ size, space, color, css, onBackground }: PropTypes) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        d="M24.002 12L16.002 20L8.00195 12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="nonzero"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default ChevronIcon;
