import CloseButton from 'Atoms/Buttons/CloseButton';
import { H2 } from 'Atoms/Typography/Headings/Heading';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';

function ReporterModal() {
  const { errorLabels } = useTranslationData();

  return (
    <>
      <Root>
        <H2>{errorLabels && errorLabels.errorHeader}</H2>
        <CloseButton
          css={{ display: 'flex' }}
          size="m"
          bgColor="primary"
          onClick={() => {
            window.location.reload();
          }}
        ></CloseButton>
        <Paragraph>{errorLabels && errorLabels.errorRequest}</Paragraph>
      </Root>
    </>
  );
}

export default ReporterModal;

const Root = styled('div', {
  w: '100%',
  backgroundColor: '$primary2',
  p: 10,
  h: '100%',
  position: 'fixed',
  zIndex: '$errorModal',
});
