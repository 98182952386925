import UserStateModel from 'Models/App/UserStateModel.interface';
import GetLoggedInStateModel from 'Models/User/GetLoggedInStateModel.interface';

import Fetcher from '../Common/Fetcher';

let abortController: AbortController = new AbortController();

function FetchUserState(url: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;

  return Fetcher<UserStateModel, GetLoggedInStateModel>(
    url,
    signal,
    (res, resolve, reject) => {
      if (res.success) {
        resolve(res.userState);
      }
    }
  );
}

export default FetchUserState;
