import { useKexLoadingCircle } from 'Kex/KexLoadingCircle';
import HeaderModel from 'Models/Headers/HeaderModel.interface';
import FetchHeader from 'Organisms/Header/FetchHeader';
import { useEffect } from 'react';
import { IS_PRODUCTION_ENV } from 'Shared/Configs/EnvConfig';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import useSWR, { mutate } from 'swr';

const HEADER_URL = '/App/GetStandardHeader?language=';

export default function useQueryHeader(initialData: HeaderModel): HeaderModel {
  const { languageRoute } = useAppSettingsData();
  const [, kexLoadingCircleDispatch] = useKexLoadingCircle();

  const { data: queryData, isValidating } = useSWR(
    `${HEADER_URL}${languageRoute}`,
    FetchHeader,
    {
      revalidateOnFocus: IS_PRODUCTION_ENV,
    }
  );

  useEffect(() => {
    kexLoadingCircleDispatch({
      type: isValidating && queryData === initialData ? 'add' : 'remove',
      from: 'useQueryHeader',
    });
  }, [isValidating, kexLoadingCircleDispatch, initialData, queryData]);

  return queryData || initialData;
}

export async function updateHeader(languageRoute: string) {
  const res = await fetch(`${HEADER_URL}${languageRoute}`);
  if (res.ok) {
    const { header } = await res.json();
    mutate(`${HEADER_URL}${languageRoute}`, header, false);
  }
}
