import { ChevronIcon } from 'Atoms/Icons';
import { useRef, useState } from 'react';
import { styled } from 'stitches.config';
import { timings, animation } from 'Theme/Settings/animation';
import { CSS } from '@stitches/react';

type CollapsableSectionType = {
  title: string;
  children: React.ReactNode | undefined;
  css?: CSS;
  fullWidth?: boolean;
  icon?: string;
  openByDefault?: boolean;
};

const CollapsableSection = ({
  children,
  title = '',
  css,
  fullWidth,
  icon,
  openByDefault = false,
}: CollapsableSectionType) => {
  const [isOpen, setOpen] = useState<boolean>(openByDefault);
  const [innerHeight, setInnerHeight] = useState<boolean>(openByDefault);

  const onToggle = () => {
    if (innerHeight) {
      setTimeout(() => setInnerHeight(!innerHeight), 300);
    } else {
      setInnerHeight(!innerHeight);
    }
    setOpen(!isOpen);
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper css={css} ref={wrapperRef} fullWidth={fullWidth}>
      <HeaderWrapper onClick={onToggle} isOpen={isOpen}>
        <TitleWrapper>
          {icon && <img src={icon} alt="" />}
          <Title>{title}</Title>
        </TitleWrapper>
        <ChevronIcon
          css={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(360deg)',
          }}
          color={'secondary'}
          size={'m'}
        />
      </HeaderWrapper>

      <InnerWrapper isOpen={isOpen} innerHeight={innerHeight}>
        {children}
      </InnerWrapper>
    </Wrapper>
  );
};

export default styled(CollapsableSection);

const Wrapper = styled('div', {
  textAlign: 'left',
  borderColor: '$collapsableBorderColor',
  borderStyle: 'solid',
  maxWidth: '528px',
  variants: {
    fullWidth: {
      true: {
        maxWidth: 'none',
      },
    },
  },
  mb: 1,
});

const HeaderWrapper = styled('div', {
  color: '$collapsableText',
  display: 'flex',
  p: 4,
  justifyContent: 'space-between',
  fontFamily: '$fontPrimary500',
  backgroundColor: '$collapsableHeaderBackground',
  alignItems: 'center',
  cursor: 'pointer',
  fs: 10,
  lh: '$lh133',
  transition: `all ${timings.threeTenths} ${animation.timingFn}`,
  variants: {
    isOpen: {
      false: {
        '@mediaMinLarge': {
          '&:hover': {
            backgroundColor: '$collapsableHoverBackground',
          },
        },
      },
    },
  },
  ls: '$lsn1',
});

const TitleWrapper = styled('div', {
  display: 'flex',
  gap: 16,
});

const Title = styled('h3', {
  mr: 2,
  fs: 9,
  color: '$collapsableText',
});

const InnerWrapper = styled('div', {
  opacity: 0,
  maxHeight: 0,
  display: 'flex',
  overflowY: 'hidden',
  color: '$collapsableText',
  backgroundColor: '$collapsableContentBackground',
  transitionDuration: timings.threeTenths,
  transitionTimingFunction: animation.timingFn,
  transitionProperty: 'opacity',
  variants: {
    isOpen: {
      true: {
        opacity: 1,
      },
    },
    innerHeight: {
      true: {
        maxHeight: 'unset',
        p: 4,
      },
    },
  },
});
