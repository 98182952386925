export const animateUnderlineNotActive = {
  '&:after': {
    transform: 'scaleX(0)',
    content: ' ',
    transition: 'transform 250ms ease-in-out',
    display: 'block',
    borderBottom: '1px solid',
    transformOrigin: '0% 0%',
  },
};

export const animateUnderlineActive = {
  '&:after': {
    transform: 'scaleX(1)',
    transformOrigin: '0% 50%',
  },
};
