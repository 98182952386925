import { DIGasellIcon, LogoIcon } from 'Atoms/Icons';
import FooterModel from 'Models/Footer/FooterModel.interface';
import LinkList from 'Molecules/LinkList/LinkList';
import XHtmlArea from 'Molecules/XHtmlArea/XHtmlArea';
import { styled } from 'stitches.config';
import AddressInfo from '../AddressInfo/AddressInfo';
import useQueryFooter from '../useQueryFooter';

type PropTypes = {
  initData: FooterModel;
};

function DesktopFooterMid({ initData }: PropTypes) {
  const {
    aboutText,
    addressText,
    footerCenterHeader,
    footerRightHeader,
    links,
  } = useQueryFooter(initData);

  return (
    <StyledGrid>
      <ColumnOne>
        <LogoIcon size="headerDesktop" fillColor="white" />
        <TextWrapper>
          <XHtmlArea content={aboutText || ''} />
        </TextWrapper>
        <DIGasellIcon />
      </ColumnOne>

      <ColumnTwo>
        <LinkList header={footerCenterHeader} links={links} />
      </ColumnTwo>

      <ColumnThree>
        <AddressInfo header={footerRightHeader} text={addressText} />
      </ColumnThree>
    </StyledGrid>
  );
}
export default DesktopFooterMid;

const fontStyleCss = {
  fontFamily: '$fontSecondary400',
  fontWeight: '$fw400',
  letterSpacing: '$ls0',
  lineHeight: '$lh125',
  color: '$secondary2',
  fs: 8,
};

const TextWrapper = styled('div', {
  ...fontStyleCss,
  pt: 4,
  opacity: 0.9,
  lineHeight: '$lh15',
  pb: 8,
});

const StyledGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '3fr 2fr 1fr',
  gap: '32px',
  pt: 16,
});

const ColumnOne = styled('div', {
  maxWidth: '433px',
  gridColumnStart: 1,
  marginRight: 'auto',
  marginLeft: '8px',
});

const ColumnTwo = styled('div', {
  maxWidth: '320px',
});

const ColumnThree = styled('div', {
  whiteSpace: 'nowrap',
  marginLeft: 'auto',
  marginRight: '8px',
});
