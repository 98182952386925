import { styled } from 'stitches.config';
import { timings } from 'Theme/Settings/animation';
import { animationStrokeDash } from 'Theme/Settings/keyframes';

import { BaseIconCircleStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

type Props = IconBaseType & {
  isLoading?: boolean;
};

function LoadingCircle({ css, isLoading, color, size, onBackground }: Props) {
  return (
    <StyledSvg
      css={css}
      size={size}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledCircle
        onBackground={onBackground}
        color={color}
        isLoading={isLoading}
        cx="50"
        cy="50"
        r="49"
      />
    </StyledSvg>
  );
}

export default LoadingCircle;

const StyledSvg = styled('svg', {
  ...BaseIconStyle,
  py: 0.25,
  px: 0.25,
  overflow: 'visible',
});

const StyledCircle = styled(BaseIconCircleStyle, {
  variants: {
    isLoading: {
      true: {
        transform: 'rotate(-90deg)',
        transformOrigin: '50%',
        animationTimingFunction: 'cubic-bezier(0.47, 0.01, 0.51, 1)',
        animationIterationCount: 'infinite',
        strokeWidth: '8px',
        strokeDashoffset: 'calc(-3.14 * 2 * 100%)',
        strokeDasharray: 'calc(3.14 * 2 * 100%)',
        stroke: '$accent',
        animationName: `${animationStrokeDash}`,
        animationDuration: timings.oneFifth,
      },
    },
  },
});
