import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function TruckIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M0.999999 18V8C0.999999 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H19C19.5304 6 20.0391 6.21071 20.4142 6.58579C20.7893 6.96086 21 7.46957 21 8V16"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M21 16V8H25C26.5913 8 28.1174 8.63214 29.2426 9.75736C30.3679 10.8826 31 12.4087 31 14V22C31 22.5304 30.7893 23.0391 30.4142 23.4142C30.0391 23.7893 29.5304 24 29 24H28"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M31 16H27C26.4696 16 25.9609 15.7893 25.5858 15.4142C25.2107 15.0391 25 14.5304 25 14V8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M25 23C25 23.7956 24.6839 24.5587 24.1213 25.1213C23.5587 25.6839 22.7956 26 22 26C21.2044 26 20.4413 25.6839 19.8787 25.1213C19.3161 24.5587 19 23.7956 19 23C19 22.2044 19.3161 21.4413 19.8787 20.8787C20.4413 20.3161 21.2044 20 22 20C22.7956 20 23.5587 20.3161 24.1213 20.8787C24.6839 21.4413 25 22.2044 25 23V23Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M9 23C9 23.7956 8.68393 24.5587 8.12132 25.1213C7.55871 25.6839 6.79565 26 6 26C5.20435 26 4.44129 25.6839 3.87868 25.1213C3.31607 24.5587 3 23.7956 3 23C3 22.2044 3.31607 21.4413 3.87868 20.8787C4.44129 20.3161 5.20435 20 6 20C6.79565 20 7.55871 20.3161 8.12132 20.8787C8.68393 21.4413 9 22.2044 9 23Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M16 24H12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default TruckIcon;
