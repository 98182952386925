import FooterModel from 'Models/Footer/FooterModel.interface';
import { styled } from 'stitches.config';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import useQueryFooter from '../useQueryFooter';
import { KlarnaIcon, VisaCardIcon, MasterCardIcon } from 'Atoms/Icons';

import KexLink from 'Kex/KexLink';
import SocialLinkModel from 'Models/SocialLink/SocialLinkModel.interface';

declare var Cookiebot: any; //Window.Cookiebot

type PropTypes = {
  initData: FooterModel;
};

const today = new Date();
const year = today.getFullYear();

function MobileFooterBottom({ initData }: PropTypes) {
  const { translations } = useAppSettingsData();
  const { termsAndConditionsLink, privacyPolicyLink, socialLinks } =
    useQueryFooter(initData);

  return (
    <>
      <Container>
        <Row>
          {socialLinks.map((item: SocialLinkModel, index: number) => {
            return (
              <StyledKexLink
                aria-label={'social media link'}
                key={item.url + index}
                href={item.url}
                underlined={false}
              >
                <CircleBackground>
                  <StyledImg src={item.icon} alt={''}></StyledImg>
                </CircleBackground>
              </StyledKexLink>
            );
          })}
        </Row>

        <Row>
          <KlarnaIcon />
          <VisaCardIcon />
          <MasterCardIcon />
        </Row>

        <Row>
          <StyledKexLink href={privacyPolicyLink.href} underlined={false}>
            {privacyPolicyLink.text}
          </StyledKexLink>

          <StyledKexLink
            href="#"
            onClick={() => {
              Cookiebot.renew();
            }}
            underlined={false}
          >
            {translations.commonLabels.cookiePreferences}
          </StyledKexLink>

          <StyledKexLink href={termsAndConditionsLink.href} underlined={false}>
            {termsAndConditionsLink.text}
          </StyledKexLink>
        </Row>
        <Row>
          <StyledKexLink underlined={false}>
            © {translations.commonLabels.copyrightText} {year}
          </StyledKexLink>
        </Row>
      </Container>
    </>
  );
}

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '100%',
  justifyContent: 'center',
  fontSize: '12px',
});

const Row = styled('div', {
  display: 'flex',
  flexBasis: '100%',
  justifyContent: 'center',
  fontSize: '12px',
  marginBottom: '32px',
  color: '$secondary2',
  gap: '24px',
});

const StyledKexLink = styled(KexLink, {
  fontSize: '12px',
  fontFamily: '$fontSecondary400',
  color: '$secondary2',
  wordSpacing: 'unset',
  marginBottom: '0px',
});

const CircleBackground = styled('span', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$primary2',
  borderRadius: '50%',
  width: '40px',
  height: '40px',

  '@mediaMinMedium': {
    transform: 'scale(1.2)',
  },

  '&:hover': {
    backgroundColor: '$primary1',
    cursor: 'pointer',
  },
});

const StyledImg = styled('img', {
  margin: 'auto',
  position: 'absolute',
});

export default MobileFooterBottom;
