import AppInitModel from 'Models/App/AppInitModel.interface';
import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import {
  AppSettingsProvider,
  useAppSettingsData,
} from 'Shared/Providers/AppSettingsProvider';
import { TranslationProvider } from 'Shared/Providers/TranslationProvider';
import { UserContextProvider } from 'Shared/Providers/UserContextProvider';
import { styled } from 'stitches.config';
import loadable from '@loadable/component';
import useCurrentPage from '../Features/Shared/Hooks/useCurrentPage';
import { KexLoadingCircle } from './Kex/KexLoadingCircle';
import KexPage from './Kex/KexPage';
import KexReporterOverlay from './Kex/KexReporterOverlay';
import { KexRouter } from './Kex/KexRouter/KexRouter';
import Breadcrumb from './Molecules/Breadcrumb/Breadcrumb';
import Footer from './Organisms/Footer/Footer';
import { Header } from './Organisms/Header/Header';
import ErrorBoundary from './Shared/ErrorBoundary/ErrorBoundary';
import globalStyles from './Styles/global.stitches';
import { ThemeProvider } from './Theme/ThemeProvider';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import KexNotificationOverlay from './Molecules/Notifications/BasicNotification';
import { StickyHeaderProvider } from 'Shared/Providers/StickyHeaderProvider';
import { getApplicationInsightsScript } from 'Node/HtmlSnippets';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TagManager from 'react-gtm-module'; // no typescript support
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import PageMetaData from 'Pages/MetaTags/PageMetaTags';

declare var Cookiebot: any; //Window.Cookiebot

type PropType = {
  appInitData: AppInitModel;
};

const NotFoundPage = loadable(
  () => import('./Pages/NotFoundPage/NotFoundPage')
);

const excludeBreadcrumb = [
  'StartPage',
  'CheckoutPage',
  'SearchPage',
  'MotorcycleConfiguratorPage',
  'CategoryPage',
];

const excludeFooter = ['MotorcycleConfiguratorPage'];

const excludeHeader = ['CheckoutPage'];

const Content = ({ appInitData }: PropType) => {
  const { pageType, inEditMode } = useCurrentPage<PageModelBase>();
  const { gtmId, applicationInsightInstrumentationKey, environment } =
    useAppSettingsData();

  useEffect(() => {
    const appInsightscript = document.createElement('script');

    appInsightscript.innerHTML = getApplicationInsightsScript(
      applicationInsightInstrumentationKey
    );

    function initMarketingScripts() {
      if (Cookiebot.consent.marketing) {
        TagManager.initialize({ gtmId });
        document.body.append(appInsightscript);

        TagManager.dataLayer({
          dataLayer: {
            environment: environment,
            context: inEditMode ? 'epi-edit' : 'server',
          },
          dataLayerName: 'site',
        });
      }
    }

    window.addEventListener('CookiebotOnAccept', initMarketingScripts);

    return () => {
      window.removeEventListener('CookiebotOnAccept', initMarketingScripts);
      document.body.removeChild(appInsightscript);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtmId, applicationInsightInstrumentationKey]);

  return (
    <HelmetProvider>
      <Container id="root-content">
        <PageMetaData />
        {pageType === 'BlockPreviewPage' ? (
          <KexPage />
        ) : (
          <>
            {pageType && !excludeHeader.includes(pageType) && (
              <Header initData={appInitData.header} />
            )}
            {pageType && !excludeBreadcrumb.includes(pageType) && (
              <ContentContainer>
                <Breadcrumb />
              </ContentContainer>
            )}
            <KexPage />
            {pageType && !excludeFooter.includes(pageType) && (
              <Footer initData={appInitData.footer} />
            )}
            <KexNotificationOverlay />
          </>
        )}
      </Container>
    </HelmetProvider>
  );
};

const Container = styled('div', {
  // fallback styling since we can't set it in body because of stitches is not loaded there
  backgroundColor: '$rootBackgroundColor',
  color: '$textPrimary',
});

function App({ appInitData }: PropType) {
  globalStyles();

  return (
    <AppSettingsProvider data={appInitData.appSettings}>
      <TranslationProvider data={appInitData.appSettings.translations}>
        <ThemeProvider>
          <UserContextProvider data={appInitData.userState}>
            <StickyHeaderProvider>
              <KexReporterOverlay />
              <ErrorBoundary FallbackComponent={() => <NotFoundPage />}>
                <KexLoadingCircle>
                  <KexRouter
                    appInitData={appInitData}
                    initUrl={appInitData.initUrl}
                  >
                    <Content appInitData={appInitData} />
                  </KexRouter>
                </KexLoadingCircle>
              </ErrorBoundary>
            </StickyHeaderProvider>
          </UserContextProvider>
        </ThemeProvider>
      </TranslationProvider>
    </AppSettingsProvider>
  );
}

export default App;
