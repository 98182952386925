import { keyframes } from 'stitches.config';

export const animationCrossIn = keyframes({
  '0%': { transform: 'scale(0)' },
  '90%': { transform: 'scale(1.1)' },
  '100%': { transform: 'scale(1)' },
});

export const animationCrossOut = keyframes({
  '0%': { transform: 'scale(1)' },
  '10%': { transform: 'scale(1.1)' },
  '100%': { transform: 'scale(0)' },
});

export const animationPathsIn = keyframes({
  from: { opacity: '0' },
  to: { opacity: '1' },
});

export const animationPathsOut = keyframes({
  from: { opacity: '1' },
  to: { opacity: '0' },
});

export const animationStrokeDash = keyframes({
  '0%': {
    strokeDashoffset: 'calc(3.14 * 2 * 100%)',
  },
  '50%': {
    strokeDashoffset: '0%',
  },
  '100%': {
    strokeDashoffset: '-calc(3.14 * 2 * 100%)',
  },
});

export const animationFrames = keyframes({
  '0%': {
    opacity: 0.2,
  },
  '20%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0.2,
  },
});

export const animationRotation = keyframes({
  '100%': {
    transform: 'rotate(360deg)',
  },
});

export const animationLoadingStrokeDash = keyframes({
  '0%': {
    strokeDasharray: '1, 150',
    strokeDashoffset: '0',
    stroke: '$JE68GreenPrimary',
  },
  '50%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: '-35',
    stroke: '$JE68GreenPrimary',
  },
  '100%': {
    strokeDasharray: '90, 150',
    strokeDashoffset: '-124',
    stroke: '$JE68GreenPrimary',
  },
});

export const animationStrokeDashIn = keyframes({
  '0%': {
    strokeDashoffset: 'calc(3.14 * 2 * 100%)',
  },
  '50%': {
    strokeDashoffset: '0%',
  },
  '100%': {
    strokeDashoffset: '-calc(3.14 * 2 * 100%)',
  },
});

export const animationTranslateInDesktop = keyframes({
  '0%': {
    transform: 'translateY(-68px)',
  },
  '30%': {
    transform: 'translateY(68px)',
  },
  '50%': {
    transform: 'translateY(68px)',
  },
  '80%': {
    transform: 'translateY(68px)',
  },
  '100%': {
    transform: 'translateY(-68px)',
  },
});

export const animationTranslateInMobile = keyframes({
  '0%': {
    transform: 'translateY(101vh)',
  },
  '30%': {
    transform: 'translateY(calc(100vh - 170px))',
  },
  '50%': {
    transform: 'translateY(calc(100vh - 170px))',
  },
  '80%': {
    transform: 'translateY(calc(100vh - 170px))',
  },
  '100%': {
    transform: 'translateY(101vh)',
  },
});

export const animationMarqueeMobile = keyframes({
  '0%': {
    transform: 'translateX(100vw)',
    opacity: 100,
  },

  '100%': {
    transform: 'translateX(-250vw)',
    opacity: 100,
  },
});

export const animationMarqueeTablet = keyframes({
  '0%': {
    transform: 'translateX(100vw)',
    opacity: 100,
  },

  '100%': {
    transform: 'translateX(-110vw)',
    opacity: 100,
  },
});
