import { styled } from 'stitches.config';

export const BaseIconStyle = styled('svg', {
  overflow: 'visible',

  variants: {
    size: {
      xs: {
        w: 2,
        h: 2,
      },
      s: {
        w: 4,
        h: 4,
      },
      m: {
        w: 6,
        h: 6,
      },
      l: {
        h: 8,
        w: 8,
      },
      xl: {
        h: 10,
        w: 10,
      },
      headerMobile: {
        h: 4.5,
        w: 19,
      },
      headerDesktop: {
        h: 8,
        w: 30,
      },
      footer: {
        h: 6,
        w: 16,
      },
    },
    space: {
      true: {
        mr: 2,
      },
    },
  },
  defaultVariants: {
    size: 's',
  },
});

const baseVariations = {
  variants: {
    color: {
      primary: {
        stroke: '$JE68GreenPrimary',
      },
      primary2: {
        stroke: '$JE68GreenPrimary',
      },
      secondary: {
        stroke: '$secondary2',
      },
      tertiary: {
        stroke: '$pureWhite',
      },
      quaternary: {
        stroke: '$primary2',
      },
      secondaryDark: {
        stroke: '$primary6',
      },
      accent: {
        fill: '$fillAccent',
      },
      error: {
        stroke: '$fillError',
      },
      white: {
        fill: '$pureWhite',
      },
      toggle: {
        fill: '$toggleActiveColor',
      },
      transparent: {
        stroke: '',
      },
    },
    fillColor: {
      primary: { fill: '$JE68GreenPrimary' },
      secondary: { fill: '$secondary2' },
      secondaryDark: { fill: '$primary6' },
      white: {
        fill: '#ffffff',
      },
      transparent: { fill: 'transparent' },
      outlined: { fill: '$secondary2' },
    },
    onBackground: {
      true: {},
    },
  },
  defaultVariants: {
    color: 'transparent',
    fillColor: 'transparent',
  },
};

export type IconColorVariants = keyof typeof baseVariations.variants.color;
export type IconFillColorVariants =
  keyof typeof baseVariations.variants.fillColor;

export const BaseIconPathStyle = styled('path', { ...baseVariations });

export const BaseIconCircleStyle = styled('circle', { ...baseVariations });
