import HeaderModel from 'Models/Headers/HeaderModel.interface';
import StartPageModel from 'Models/Pages/StartPage/StartPageModel.interface';
import React, { useState } from 'react';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import useMedia from 'Shared/Hooks/useMedia';
import useQueryHeader from 'Shared/Hooks/useQueryHeader';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { styled } from 'stitches.config';
import { isEmpty } from 'Shared/Common/Helpers';
import loadable from '@loadable/component';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import {
  animationMarqueeMobile,
  animationMarqueeTablet,
} from 'Theme/Settings/keyframes';

const MobileHeader = loadable(
  () => import(/* webpackPrefetch: true */ './MobileHeader/MobileHeader')
);
const DesktopHeader = loadable(
  () => import(/* webpackPrefetch: true */ './DesktopHeader/DesktopHeader')
);

type PropType = {
  initData: HeaderModel;
};

export const HeaderContext = React.createContext({});

function Header({ initData }: PropType) {
  const headerData = useQueryHeader(initData);
  const { inEditMode, uspContainer } = useCurrentPage<StartPageModel>();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);
  const [marquee, setMarquee] = useState<boolean>(true);

  return (
    <HeaderContext.Provider value={headerData}>
      {/* episerver does not like loading both headers while in on-page-edit. When we do, it messes up some other CSS. */}
      {!isEmpty(uspContainer) && (
        <UspContainer>
          <ContentContainer>
            <UspWrapper
              onClick={() => {
                setMarquee(!marquee);
              }}
            >
              {uspContainer.map((u, i) => {
                return (
                  <Usp key={u.usp + i} isRunning={marquee}>
                    <UspIcon src={u.image.src} alt=""></UspIcon>
                    <UspLabel>{u.usp}</UspLabel>
                  </Usp>
                );
              })}
            </UspWrapper>
          </ContentContainer>
        </UspContainer>
      )}
      {isDesktop || inEditMode ? <DesktopHeader /> : <MobileHeader />}
    </HeaderContext.Provider>
  );
}

const useHeaderData = () => {
  return React.useContext(HeaderContext) as HeaderModel;
};

export { Header, useHeaderData };

const UspContainer = styled('div', {
  backgroundColor: '$JE68GreenPrimary',
  position: 'relative',
  zIndex: '$Header',
  py: 2,
  overflow: 'hidden',
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    display: 'none',
  },
  '&::scrollbar': {
    display: 'none',
  },
});

const UspWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 64,
});

const Usp = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: 8,
  animation: `${animationMarqueeMobile}`,
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear',

  animationDuration: '19.5s',
  '@mediaMinMedium': {
    animation: `${animationMarqueeTablet}`,
    animationDuration: '15.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    opacity: 0,
  },
  '@mediaMinLarge': {
    animation: 'none',
    opacity: 100,
  },

  variants: {
    isRunning: {
      true: {
        animationPlayState: 'running',
      },
      false: {
        animationPlayState: 'paused',
      },
    },
  },
});

const UspIcon = styled('img', {
  height: '16px',
  width: '16px',
});

const UspLabel = styled('span', {
  fontFamily: '$fontSecondary500',
  lineHeight: '$lh133',
  fs: 6,
  color: '$primary6',
  whiteSpace: 'nowrap',
});
