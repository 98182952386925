import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function WrenchIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M28.2874 16.808C29.664 15.432 30.5723 13.6574 30.8835 11.736C31.1946 9.81469 30.8927 7.84413 30.0207 6.104L25.2874 10.832C24.9123 11.2069 24.4037 11.4176 23.8734 11.4176C23.3431 11.4176 22.8345 11.2069 22.4594 10.832L21.3927 9.76534C21.2069 9.5796 21.0594 9.35907 20.9589 9.11633C20.8583 8.8736 20.8065 8.61342 20.8065 8.35067C20.8065 8.08792 20.8583 7.82774 20.9589 7.58501C21.0594 7.34227 21.2069 7.12174 21.3927 6.936L26.1927 2.136C24.4744 1.19775 22.5029 0.828102 20.5614 1.08013C18.6199 1.33215 16.8081 2.19289 15.3863 3.53877C13.9644 4.88464 13.0056 6.64644 12.6474 8.57121C12.2892 10.496 12.5502 12.4848 13.3927 14.252L1.60207 26.0347C1.41089 26.2256 1.25923 26.4524 1.15576 26.702C1.05228 26.9516 0.999023 27.2191 0.999023 27.4893C0.999023 27.7595 1.05228 28.0271 1.15576 28.2767C1.25923 28.5263 1.41089 28.7531 1.60207 28.944L3.05673 30.4C3.2478 30.5912 3.47467 30.7429 3.72438 30.8464C3.9741 30.9499 4.24176 31.0032 4.51207 31.0032C4.78238 31.0032 5.05004 30.9499 5.29975 30.8464C5.54947 30.7429 5.77634 30.5912 5.9674 30.4L17.7487 18.6187C19.477 19.4434 21.4185 19.7122 23.3058 19.3879C25.1931 19.0637 26.9335 18.1623 28.2874 16.808V16.808Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default WrenchIcon;
