import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function OilIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M19.7333 18.5787C18.9853 19.5402 18.0276 20.3182 16.9332 20.8534C15.8389 21.3885 14.6368 21.6667 13.4186 21.6667H3.33325C2.80282 21.6667 2.29411 21.4559 1.91904 21.0809C1.54397 20.7058 1.33325 20.1971 1.33325 19.6667V12.6667C1.33325 12.1362 1.54397 11.6275 1.91904 11.2524C2.29411 10.8774 2.80282 10.6667 3.33325 10.6667H11.6319C12.0861 10.6668 12.5267 10.8215 12.8813 11.1053L16.5333 14.0213C16.7825 14.2207 17.0758 14.3575 17.3887 14.4204C17.7016 14.4833 18.025 14.4703 18.3319 14.3827L24.3333 12.6667L19.7333 18.5787Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M9.33325 6.66666V10.6667"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M5.33325 6.66666H13.3333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M24.3333 12.6667L27.3333 13.6667"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M30.3333 24.792C30.3333 25.5544 30.0305 26.2856 29.4913 26.8247C28.9522 27.3638 28.2211 27.6667 27.4587 27.6667C26.6962 27.6667 25.9651 27.3638 25.426 26.8247C24.8868 26.2856 24.584 25.5544 24.584 24.792C24.584 23.2 26.5 20 27.4587 20C28.4173 20 30.3333 23.2 30.3333 24.792Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default OilIcon;
