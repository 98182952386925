import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function StorageIcon({ css, color, size, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg css={css} size={size} space={space} viewBox="0 0 32 32">
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M16.0085 22.8666H9.00854C8.74333 22.8666 8.48897 22.972 8.30144 23.1595C8.1139 23.3471 8.00854 23.6014 8.00854 23.8666V29.8666C8.00854 30.1319 8.1139 30.3862 8.30144 30.5737C8.48897 30.7613 8.74333 30.8666 9.00854 30.8666H15.0085C15.2738 30.8666 15.5281 30.7613 15.7157 30.5737C15.9032 30.3862 16.0085 30.1319 16.0085 29.8666V22.8666Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M23.0085 22.8666H16.0085V29.8666C16.0085 30.1319 16.1139 30.3862 16.3014 30.5737C16.489 30.7613 16.7433 30.8666 17.0085 30.8666H23.0085C23.2738 30.8666 23.5281 30.7613 23.7157 30.5737C23.9032 30.3862 24.0085 30.1319 24.0085 29.8666V23.8666C24.0085 23.6014 23.9032 23.3471 23.7157 23.1595C23.5281 22.972 23.2738 22.8666 23.0085 22.8666Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M19.0085 14.8666H13.0085C12.7433 14.8666 12.489 14.972 12.3014 15.1595C12.1139 15.3471 12.0085 15.6014 12.0085 15.8666V22.8666H20.0085V15.8666C20.0085 15.6014 19.9032 15.3471 19.7157 15.1595C19.5281 14.972 19.2738 14.8666 19.0085 14.8666Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M31.0085 30.8667V10.0667C31.0087 9.70408 30.9102 9.34828 30.7238 9.03733C30.5373 8.72638 30.2698 8.47197 29.9499 8.30132L16.9499 1.36799C16.6603 1.21368 16.3373 1.13297 16.0092 1.13297C15.6811 1.13297 15.3581 1.21368 15.0685 1.36799L2.06854 8.30132C1.74839 8.4718 1.48062 8.72613 1.2939 9.03709C1.10718 9.34805 1.00854 9.70394 1.00854 10.0667V30.8667"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default StorageIcon;
