import { styled } from 'stitches.config';

import { BaseIconPathStyle, BaseIconStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function UserIcon({ css, color, size, onBackground }: IconBaseType) {
  return (
    <StyledSvg
      size={size}
      css={css}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <StyledPath
        d="M12.0001 13.7143C14.367 13.7143 16.2858 11.7955 16.2858 9.42854C16.2858 7.0616 14.367 5.14282 12.0001 5.14282C9.63313 5.14282 7.71436 7.0616 7.71436 9.42854C7.71436 11.7955 9.63313 13.7143 12.0001 13.7143Z"
        color={color}
        onBackground={onBackground}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        d="M4.67993 20.4C5.44492 19.1443 6.52006 18.1066 7.802 17.3864C9.08394 16.6663 10.5296 16.2881 11.9999 16.2881C13.4703 16.2881 14.9159 16.6663 16.1979 17.3864C17.4798 18.1066 18.5549 19.1443 19.3199 20.4"
        color={color}
        onBackground={onBackground}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledPath
        d="M12 23.1429C18.1541 23.1429 23.1429 18.1541 23.1429 12C23.1429 5.846 18.1541 0.857178 12 0.857178C5.846 0.857178 0.857178 5.846 0.857178 12C0.857178 18.1541 5.846 23.1429 12 23.1429Z"
        color={color}
        onBackground={onBackground}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default UserIcon;
